.popup-overlay {
  align-items: center;
  /* background: #00000080; */
  display: flex;
  height: 100%;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}


.popup-container {
  background: #fff;
  /* border-radius: 10px; */
  box-shadow: 0 4px 10px #0003;
  width: 481px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-header h2 {
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.popup-form {
  display: flex;
  flex-direction: column;
  padding: 10px 20px !important;
  min-height: 84vh;
  overflow-y: scroll;
}

.popup-form label {
  margin-top: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #4A4D50;
}

.popup-form input,
.popup-form select {


  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  padding: 8px;
  padding: 13px;
}

.file-upload {
  border: 2px dashed #ccc;
  margin-top: 10px;
  padding: 30px;
  text-align: center;
  border-radius: 10px;
  background-color: #F7F8F9;
}

.file-upload p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 14px;
}

.browse {
  color: black;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.add-user-btn {
  background: #fdb813;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 15px;
}

.popup_header {
  padding: 0px !important;
}

.popup_header_main h2 {
  font-size: 18px !important;
  font-weight: 600;
}

.popup_header_main p {
  font-size: 14px;
}

.popup_header_main {
  border-bottom: 1px solid #dbdee1;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 14px;
}

.popup_header .close-btn {
  font-size: 15px;
}

.popup-form-footer {
  position: relative;

  bottom: 14px;
  border-top: 1px solid #dcdee1;
  width: 100%;
}

.popup-form-footer button {

  text-align: right;

  right: 0px;
  top: 0p;
  position: fixed;
  right: 16px;
  font-size: 14px;
}