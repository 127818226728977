.add_new_company_form {
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    font-family: Arial, sans-serif;
    /* margin-bottom: 70px; */
    max-height: 74vh;
    overflow-y: scroll;
}

.file_selected {
    font-size: 13px;
    color: green;
}

.form_title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.form_section {
    margin-bottom: 25px;
}

.section_title {

    font-weight: 600;
    margin-bottom: 15px;

    font-size: 14px;
    color: black;
}

.form_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.grid_span_2 {
    grid-column: span 2;
}

.form_label {
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #4A4D50;

}

.form_input,
.form_select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.file_upload {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    background-color: #f9f9f9;
    width: 50%;
}

.file_upload_browse {
    color: #007BFF;
    cursor: pointer;
}

.file_upload_note {
    font-size: 12px;
    color: #999;
}

.add_user_button_container {
    text-align: left;
}

.add_user_button {

    background: none;

    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #4b4d50;
    color: #4b4d50;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 20px;
}

.submit_button {
    background-color: #FFC107;
    color: #6B5202;
    padding: 12px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* margin-left: 20px; */
    display: flex;
    float: right;
    margin-right: 31px;
}

.submit_footer_fixed {
    position: absolute;
    bottom: 0px;
    background-color: white;
    width: 102%;
    z-index: 000;
    left: -2%;
    /* border-top: 1px solid #cccccc; */
    padding: 10px;
    box-shadow: 0px 1px 3px #a6a2a2;
}

.contentbar {
    position: relative;
}

.remove_user_button {
    background-color: transparent;
    color: #4c4d50;
    border: none;
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #4c4d50;
    font-size: 13px;
}

.remove_user_button:hover {
    background-color: black;
    color: white;
}

.close_btn_users {
    position: absolute;
    top: 0px;
    right: 0;
}