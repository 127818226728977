.about_company_sec {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    margin-top: 10px;

}

.about_company_sec .about_heading {
    display: flex;
    justify-content: space-between;
}

.about_company_sec .about_heading h4 {
    font-size: 14px;
}

.about_detail p {
    font-size: 13px;
}

.about_detail p b {
    font-weight: 500;
    color: black;
}

.about_detail p:first-child {
    margin-bottom: 2px;
}

.about_details {
    padding-top: 10px;
}

.editIcon-new {
    width: 25px;
    height: 25px;
    cursor: pointer;
}