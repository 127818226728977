/* Interviews.css */

.interviews-container {
    font-family: Arial, sans-serif;
    margin: 20px;
  }
  
  .tabs {
    display: flex;
    border-bottom: 2px solid #eaeaea;
    margin-bottom: 20px;
  }
  
  .tab-button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    font-size: 13px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
  }
  
  .tab-button.active-tab {
    color: #4A4D50;
    border-bottom-color: #4A4D50;
    font-weight: bold;
  }
  
  .tab-button:hover {
    color: #4A4D50;
  }
  
  .interviews-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .interview-card {
    border: 1px solid #eaeaea;
    border-radius: 10px;

    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .header-card{
    background-color: #e8e9eb;
    padding: 15px 20px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .header-card p{
    margin-bottom: 0px;
    font-weight: 600;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .interview-date {
    font-size: 14px;
    color: #6c757d;
    margin-bottom: 5px;
  }
  
  .interview-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .interview-attendees {
    font-size: 14px;
    color: #6c757d;
  }
  
  .status {
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
  
  .status.accepted {
    background-color: #28a745;
  }
  
  .status.pending {
    background-color: #ffc107;
    color: #212529;
  }
  
  .card-body {
    margin-bottom: 15px;
    padding: 0px 20px;
  }
  
  .card-body h6{
    font-weight: 600;
    color: #4A4D50;
    font-size: 14px;
  }
  .meeting-link {
    display: inline-block;
    text-decoration: none;
    color: #ffc107;
    font-weight: bold;
  }
  
  .meeting-link:hover {
    text-decoration: underline;
  }
  
  .card-actions {
    display: inline-block;
    gap: 10px;
  }


  .card-actions button{
    display: inherit;
        width: 100%;
  }
.join-meeting,
  .add-to-calendar,
  .reschedule,
  .cancel {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .join-meeting{
    background-color: #ffc107;
    color: #6B5202;
    padding: 15px 9px;
  }
  
  .add-to-calendar {
    background-color: transparent;
    color: #B0B7BE;
    border: 1px solid #B0B7BE;
  }
  
  .add-to-calendar:hover {
    background-color: transparent;
    color: #212529;
    border: 1px solid #212529;
  }
  
  .reschedule {
    background-color: transparent;
    color: #B0B7BE;
    border: 1px solid #B0B7BE;
  }
  
  .reschedule:hover {
    background-color: transparent;
    color: #212529;
    border: 1px solid #212529;
  }
  
  .cancel {
    background-color: transparent;
    color: #B0B7BE;
    border: 1px solid #B0B7BE;
  }
  
  .cancel:hover {
    background-color: transparent;
    color: #212529;
    border: 1px solid #212529;
  }
  .short_meeting_details{
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 0px 20px;
  }

  .short_meeting_details .meeting_with img{
    margin-right: 10px;
    margin-left: 10px;
  }

  .short_meeting_details .meeting_with span{
    font-weight: 600;
    color: #034A8C;
  }

  .meeting_status p{
    font-weight: 500;
    font-size: 15px;
  }

  .meeting_status {
    position: relative;
  }

  .meeting_status::before{
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #28a745;
    left: -17px;
    top: 12%;
   
  }

  .meeting_details p{
    font-weight: 500;
    font-size: 15px;
  }

  .meeting_details_card {
    display: flex;
    padding-right: 20px;
    padding-bottom: 20px;
}

.card-body p{
  margin-bottom: 0px;
}