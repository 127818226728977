.target_industries
{
    background-color: white;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0px 1px 7px #7a7a7a2b;
    padding-bottom: 5px;
  

    position: relative;
}

.target_industries h4{
    font-size: 18px;
    font-weight: 600;
    
}

.industry-tag{
    margin-bottom: 0px;
    padding-bottom: 0px !important;
}
.empty-state {
    margin: 0 auto;
    text-align: center;
    
    padding-bottom: 30px;
}
.empty-state img{
    width: 160px;

    
}
.empty-state p{
    color: #7D8287;
    font-size: 17px;
    padding-bottom: 10px;
}
.empty-state button{

    margin-bottom: 10px;
    border: 1px solid #ffc500;
    color: #5f6063;
    background-color: #ffc500;
    padding: 13px 30px;
    font-weight: 400;
    border-radius: 10px;
    font-size: 14px;
}

.empty-state .plusIcon img{
    width: 30px;
    position: absolute;
    right: 2%;
    top: 6%;
    cursor: pointer;
}


.exist-target-value span{
   border: 1px solid #5f6063;
    padding: 6px 0px;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 14px !important;
    margin-bottom: 5px;
}

.exist-target-value{
    padding:10px 0px;
}

.target-list-options{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}