.industry-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    max-height: 80%;
    overflow-y: auto;
    border: 1px solid #cacfd3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
}

.industry-search-bar {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;

}

.selected-industries {
    padding: 10px;
    font-size: 14px;
    color: #333;
}


.filter-options h6 {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #4b4d50;
    font-size: 14px;
    margin: 0;
}

.industry-list-radio-buttons {
    padding: 10px 0;
}

.industry-list-radio-buttons label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.industry-list-radio-buttons input[type="radio"] {
    margin-right: 5px;
}

.modal-footer {
    margin-top: 15px;
}

.done-button {
    background-color: #ffc500;
    color: #6c5202;
    padding: 12px 15px;
    border-radius: 8px;
    font-size: 14px;
    border: none;
    width: 100%;
    cursor: pointer;
}

.selected_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.manage-icons {
    display: flex;
    align-items: center;
}

.manage-icons svg {
    width: 22px;
    cursor: pointer;
}

.manage-icons svg:first-child {
    margin-right: 5px;
}

.no-results {
    text-align: center;
    margin-top: 10px;
    color: #888;
    font-size: 14px;
}