.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-header h3 {
  margin: 0;
}

.modal-main .close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}



.filter-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 300px;
  overflow-y: auto;
}

.filter-item {
  padding: 6px 10px;
  margin-bottom: 5px;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 400;
}

.filter-item img {
  width: 20px;
  margin-right: 10px;
}



.filter-item:hover {
  background-color: #e0e0e0;

}

.modal-main .modal-content {
  width: 100%;
  background-color: transparent;
  padding: 0px;
  box-shadow: none;
  margin-top: 10px;
}

.modal-main .modal-content input {
  padding: 13px 15px;
  border-radius: 10px;
  border: 1px solid #cacfd3;
  margin-bottom: 10px;
}


.modal-main .modal-content .modal-body {
  background: #fff;
  padding: 20px 10px;
  width: 100%;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #cacfd3;
}

.manager-list-radio-buttons {
  padding-top: 15px;
}

.manager-list-radio-buttons label {
  display: block;
  padding-bottom: 10px;
}

.manager-list-radio-buttons label input {
  margin-right: 10px;
}

.filter-box-main {
  background-color: white;
  height: 84vh;
  overflow-y: scroll;
  padding: 15px 20px;
}

.main-box-filter {
  min-height: auto !important;
  overflow-y: inherit !important;
  right: 12px !important;
}