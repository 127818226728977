/* ColumnEditModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* ensure it’s above other elements */
  }
  
  .modal-edit-column {
    min-width: 700px !important;
  }
  
  .modal-edit-column .modal-content{
    width: 700px;
    padding: 0px;
  }
  .edit-column-box{

    display: flex;
    padding: 30px 20px;
  }
  .edit-column-box .modal-content{
    padding:10px 0px;
  }

  .leftsie_column_edit, .rightside_column_edit{
    height: 280px;
    overflow-y: scroll;
    width: 300px;
    margin-right: 20px;
  }

  .modal-body h6{
    font-size:14px;
    padding-bottom: 15px;
    color: #666666;
  }

  .leftsie_column_edit .form-check{
    padding-bottom: 10px;
    color: #666666;
    font-weight: 300;
    font-size: 15px;
  }

  .form-check-input:checked[type=checkbox]{
    width:20px;
    height: 20px;
    margin-right: 10px;
  }

  .form-check-input[type=checkbox]{
    width:20px;
    height: 20px;
    margin-right: 10px;
  }

  .modal-title{
    font-size: 19px;
    font-weight: 600;
  }

  .modal-header{
    padding-top: 20px !important;
    padding-bottom: 20px;
  }

  .btn-close{
    font-size: 8px;
    border: 1px solid black;
    border-radius: 20px;
    color: black;
    font-weight: 600;
    margin-right: 2px !important;
  }

  tbody tr td:first-child{
    cursor: pointer;
  }

  .btn-save{
    background-color: #ffc500;
    font-size: 14px;
    padding: 10px;
  }