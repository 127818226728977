.note_list_section .note_box{
    background-color: white;
    padding:20px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.note_list_section{
    padding: 20px 0px;
}

.user_info_date{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}

.user_info_date h6{
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 0;
}


.note_list_section h1{
    font-size: 24px;
}

.note_list_section .user_profile_info{
    display: flex;
    align-items: center;

}

.note_list_section .user_profile_info img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.note_list_section p{
    font-size: 14px;
}