.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.add-title-popup-content {
    background: white;

    border-radius: 8px;
    width: 450px;
    position: relative;
    text-align: center;
}

.add_popup_title {
    padding: 20px;
    text-align: left;
}

.popup-actions .cancel-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    font-size: 13px;
}


.popup-textarea {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    border-radius: 10px;
}

.popup-actions {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #cccccc;
    padding: 15px;
    padding-bottom: 1px;
}

.cancel-button,
.add-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background: #ccc;
}

.add-button {
    background: #28a745;
    color: white;
}