.resume-sec {
    background-color: white;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0px 1px 7px #7a7a7a2b;
    padding-bottom: 5px;
    margin-top: 14px;

    position: relative;
}

.resume-sec h4 {

    font-weight: 600;

}

.empty-state {
    margin: 0 auto;
    text-align: center;

    padding-bottom: 30px;
}

.empty-state img {
    width: 160px;


}

.empty-state p {
    color: #7D8287;
    font-size: 17px;
    padding-bottom: 10px;
}

.empty-state button {

    margin-bottom: 10px;
    border: 1px solid #ffc500;
    color: #5f6063;
    background-color: #ffc500;
    padding: 13px 30px;
    font-weight: 400;
    border-radius: 10px;
    font-size: 14px;
}

.empty-state .plusIcon img {
    width: 30px;
    position: absolute;
    right: 2%;
    top: 15px;
    cursor: pointer;
}

.resume-link img {
    width: 40px;
}


.resume-link {
    display: flex;
    font-size: 13px;
    justify-content: space-between;
    align-items: center;
}

.resume-link button {
    border: 1px solid gray;
    background-color: transparent;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
}

.show-state {
    padding: 15px 0px;
}

/* Initially hidden modal */
.editIcon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.editIcon img {
    width: 25px;
}

.resume-sec {
    position: relative;
}

.resume-sec h4 {
    font-size: 15px;
}