.verification_status_section{
    padding: 20px;
    background-color: white;
    margin-top: 10px;
    border-radius: 10px;
}

.verificaiton_button_list{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.verificaiton_button_list p{
    margin-bottom: 0px;
}

.verification_status_section h4{
    font-size: 14px;
}

.verification_list .verifiedIcon{
    margin-left: 0px;
    margin-right: 10px;
}

.verificaiton_button_list button{
    border: 1px solid #034A8C;
    background-color: transparent;
    color: #034A8C;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
}