.plan-section {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background-color: white;
  align-items: center;
}

.change-plan-btn {
  background-color: #9747FF;
  color: white;
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;

}

.current-plan-label h3 {
  font-size: 16px;
  font-weight: 600;
}

.current-plan-label p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-weight: 600;
}