.form-field{
    padding-top: 10px;
}

.form-field label{
    font-size: 13px;
}

.form-field textarea{
    min-height: 70px;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.form-field input{
    padding: 10px 10px;
}
.btn-saved{
    display: flex;
    justify-content: flex-end;
}

.btn-saved button{
    border-radius: 10px ;
}
