.banner_course{
    background-color: #011324;
    padding: 20px;
    border-radius: 10px;
}

.banner_course .row{
    align-items: center;
    color: white;
}

