.add-candidate-form {
    position: relative;
    width: 100%;
    right: 0;
    top: 0;
    background-color: white;
    padding: 0px;
    border-radius: 10px;
    overflow-y: scroll;
}

.add-candidate-form form {
    min-height: 80vh;
}

.add_candidates_header {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dcdee1;
    align-items: center;

}

.main_heading_dashboard-add-new h4 {
    color: black;
    font-size: 20px;
    padding-bottom: 9px;
}

.add_candidates_header img {
    width: 20px;
    margin-right: 5px;
}

.add_candidates_header h4 {
    font-size: 18px;
    color: #3c3c3c;
    display: flex;
    align-items: center;
}

.add_candidates_header h6 {
    margin-bottom: 0px;
}

.add-candidate-form form {
    padding: 20px;
}

.add-candidate-form .form-group {
    padding-bottom: 14px;
}

.add-candidate-form .form-group label {
    font-size: 14px;
    color: black;
    font-weight: 600;
    margin-bottom: 5px;
}

.add-candidate-form .form-group input,
.add-candidate-form .form-group select {
    padding: 13px 10px;
    width: 100%;
    border: 1px solid #dcdee1;
    border-radius: 5px;
}

.add-candidate-form .form-row {
    display: flex;
    gap: 10px;

}

.add-candidate-form .form-group {
    flex: 1;
}

.add-candidate-form .form-group label {
    margin-bottom: 5px;
    display: block;
}



.sub_heading_add_new {
    font-weight: 600;
    font-size: 15px;
    padding: 10px 0px;
}

textarea {
    padding: 10px;
    border-radius: 5px;
    min-height: 100px;
}

.job-title-tags {
    display: flex;
}

.certification-tags {
    display: flex;
}

.industry-tags {
    display: flex;
}

.create-button-industry {
    width: 100%;
    background-color: transparent;
    border: 1px solid #dddee1;
    text-align: left;
    font-size: 14px;
    border-radius: 5px;
}

.create-button-industry .remove-tag {
    color: black;
}

.create-button-rank {
    width: 100%;
    background-color: transparent;
    border: 1px solid #dddee1;
    text-align: left;
    font-size: 14px;
    border-radius: 5px;
}