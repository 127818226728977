.modal-content {
    background: #fff;
    padding: 20px;
    width: 400px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .upload-box {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
   
    margin-top: 30px;
    margin-bottom: 15px;
    border-radius: 10px;
    background-color: #F7F8F9;
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  
  .image-preview img {
    max-width: 100px;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .progress-bar {
    background: #f3f3f3;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 0;
  }
  
  .progress {
    background: #4caf50;
    height: 10px;
    transition: width 0.3s;
  }
  
  .error-message {
    color: red !important;
    font-size: 12px;
    text-align: center;
    border: 1px dashed red;
    padding: 5px;
    border-radius: 10px;
  }
  

  .popup-header .profile img{
    width: 100px;
    height: 100px;
    border-radius: 15px;
    object-fit: cover;
  }

  .popup-header .profile{
    display: flex;

  }

  .popup-header .profile-detail{
    padding-left: 15px;
  }

  .popup-header .profile-detail h2{
    font-size: 22px;
  }

  .popup-header .profile span{
    background-color: #8CC7FF;
    color: #02386B;
    padding: 3px 30px;
    border-radius: 10px;
    margin-left: 14px;
  }



  .close-btn-resume{
    position: absolute;
    right: 20px;
    top: 20px;
    border: 1px solid #aca8a8;
    padding: 5px 26px;
    border-radius: 10px;
    background-color: transparent;
  }
  
/* Overlay background for modal (fade-in effect) */
.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  transition: opacity 0.5s ease; /* Fade in/out effect for the background */
}

/* Initially hidden modal */
.modal-hidden {
  transform: translateX(100%); /* Modal starts off-screen (to the right) */
  opacity: 0; /* Modal is hidden */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transition for both transform and opacity */
}

/* Visible modal with slide-in effect */
.modal-visible {
  transform: translateX(0); /* Slide-in to its position */
  opacity: 1; /* Fully visible */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth transition */
}


.resume-img{
  width: 80px !important;
  height: 80px !important;
}

.resume-candidate-name{
  font-size: 17px !important;
}

.resume-type{
  font-size: 12px;
  height: fit-content;

}

.resume-default-img{
  width: 80px;
  height: 80px;
  border-radius: 15px;
  margin-right: 0;
}

.resume-default-img h2{
  font-size: 35px !important;
}

.uploadImg{
  width: 50px;
}

.upload-instructions{
  padding-top: 10px;
  font-weight: 600;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.supported-formats{
  padding-top: 5px;
  font-size: 12px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.progressbar_resume{
  background: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin: 0px !important;
  margin-top: 14px !important;
}

.upload_resume .progress-bar{
margin: 0px !important; 
margin-bottom: 10px !important;
}