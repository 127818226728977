.users_section{
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    margin-top: 10px;
}

.users_section .users_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.users_header button{
    border: 1px solid #034A8C;
    background-color: transparent;
    color: #034A8C;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
}
.users_section .users_header h4{
    font-size: 14px;
  
}

.users_lists{
    padding-top: 15px;
}
.users_list{
    padding: 10px;
    border: 1px solid #CACFD3;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
}

.users_list h5{
    display: flex;
    font-size: 14px;
    color: black;
    align-items: center;
}



.userinfo{
    display: flex;
    justify-content: space-between;
}
.users_list .userType{

    margin-left: 10px;
    font-weight: 300;
    font-size: 12px;
}

.users_list .users_detail{
    display: flex;
    gap:20px;
    font-size: 12px;
}

.show_more button{
    border: none;
    background-color: transparent;
    color: #034A8C;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    font-size: 14px;
}