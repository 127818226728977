/* Sidebar.css */
aside {
    width: 250px;
    /* Adjust the width as needed */
    background-color: white;
    color: #4E5154;
    padding: 10px;
    box-sizing: border-box;
    height: 100%;
    transition: width 0.3s ease;
    position: relative;
    max-height: 91vh;
    z-index: 999;
}

aside.collapsed {
    width: 100px;
}

.collapsed .sidebar-icons {
    width: 25px;
}


.company-name {
    display: flex;
}

aside ul {
    list-style: none;
    padding: 0;
}

aside ul li {
    margin-bottom: 10px;
    border-radius: 10px;
}

aside ul li a {
    color: #4E5154;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-decoration: none;
    font-weight: 500;
    font-size: 13px;
}

aside ul li a:hover {
    background-color: #f7f8f9;
}

aside ul .active {
    background-color: #ffc500;
    color: white;


}

aside ul .active a {
    color: #7c5b26;

}

aside ul .active a img {
    filter: invert(34%) sepia(75%) saturate(664%) hue-rotate(2deg) brightness(57%) contrast(63%);
}

.profile-box-wrap {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    background-color: #F7F8F9;
    border-radius: 10px;
    border: 1px solid #DBDEE1;
    margin-bottom: 20px;
}

.profile-box-wrap img {
    width: 55px !important;
    height: 55px;
    border-radius: 50%;
    margin-right: 6px;
}

.profile-box-wrap h6 {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    font-size: 15px;
    margin: 0;
    text-transform: capitalize;
}

a {
    text-decoration: none !important;
}

.badgeIcon {
    width: 20px !important;
    height: 20px !important;
}

.sidebar-icons {
    width: 32px;
    padding-right: 10px;
}

.toggle-btn {
    background-color: #ffffff;
    color: #4E5154;
    padding: 6px 20px;
    /* cursor: pointer; */
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
    width: 95%;
    bottom: 20px;
    left: 0;
    margin: 0px 6px;
    position: absolute;
    cursor: pointer;
    font-size: 14px;
}

.toggle-btn:hover {
    background-color: #f7f8f9;
}

.collapsed .toggle-btn {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.collapsed .company-name,
.collapsed h6 {
    display: none;
}

.collapsed .sidebar-icons {
    padding-right: 0;
    display: block;
    margin: 0 auto;
}

.collapsed .toggle-btn img {
    width: 30px;
}

.upgrade-btn {
    background-color: #9747FF;
    color: white;
    padding: 12px 10px;
    border-radius: 10px;
    width: 100%;
    color: white;
    text-align: center;
}

.upgrade-btn a {
    color: white;
    font-size: 14px;
}

.upgrade-btn img {
    width: 25px;
    margin-right: 5px;
}

.collapse-btn {
    margin-top: 20px;
    padding: 0px 15px;

}

.collapsed .upgrade-btn img {
    width: 20px;
}

.collapsed .upgrade-btn {
    width: 90%;
    margin: 0 auto;
    margin-right: 22px;
}

.sidebardefaultProfile {
    width: 60px !important;
    height: 60px !important;
    margin-right: 4px !important;
}

.sidebardefaultProfile h2 {
    font-size: 30px !important;
}

.welcome-text span {
    text-transform: capitalize;
}


@media screen and (max-width: 991px) {
    .sidebar {
        width: 8.666667%;
    }

    .contentbar {
        width: 91.333333%;
    }

    aside.collapsed {
        width: 72px;
    }

    .collapsed .sidebar-icons {
        width: 22px;
    }

    .recently-added {
        margin-right: 0px !important;
    }


    .search_filter input {
        padding: 12px 15px !important;
        width: 80%;
    }

    .main_heading_dashboard .right_content button {
        font-size: 12px;
    }

    .search_filter button {
        font-size: 12px !;
    }

    .add_new_filter_company {
        font-size: 12px
    }

    .edit_filter_company {
        font-size: 0px;
    }

    .select_options_filter select {
        font-size: 12px !important;
    }

    .main_heading_dashboard h4 {
        color: black;
        font-size: 20px;
    }

    .search_filter button {
        font-size: 12px !important;
    }
}