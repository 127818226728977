.profile_info_candidates img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.profile_visiblity {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.profile_visiblity p,
.profile_visiblity a {
  font-size: 14px;
}

.profile_visiblity a {
  color: #034A8C;
}

.profile_info_candidate_section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  position: relative;
}

.profile_info_candidates {
  display: flex;
  justify-content: space-between;
}

.profile_info_candidates h2 {
  font-size: 16px !important;
  font-weight: 600;
  text-transform: uppercase;
}


.candidate_info_name h5 {
  font-size: 14px;
}

.candidate_info_name span {
  background-color: #8CC7FF;
  color: #02386B;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
}

.profile_info_detail {
  display: flex;
  align-items: center;

}

.more_icon img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}


.location_address {
  padding-top: 20px;
  width: 60%;
}

.location_address p img {
  width: 15px;
  margin-right: 5px;
}

.location_address p {
  font-size: 12px;
  padding: 3px 0px;
  margin: 0px;
}

.icon-outline {
  color: transparent;
  stroke: black;
  stroke-width: 2;
}

.profile_info_details {
  display: flex;
  align-items: center;
}

.profile_complete {
  width: 40%;
  padding-left: 14px;
}

.profile_info_detail .location_address {
  width: 65%;
}

.account_progress {
  font-size: 12px;
  margin: 0;
  padding-bottom: 11px;
}

.managers_affiliate {
  position: relative;
}

.managers_affiliate p {
  font-size: 12px;
  margin: 0px;
  background-color: #F7F8F9;
  margin-bottom: 5px;
  padding: 5px 10px;
  width: fit-content;
  border-radius: 10px;
  color: #4A4D50;
  font-weight: 600;
}

.managers_affiliate p span {
  color: black;
  font-weight: 600;
  margin-left: 5px;
  font-size: 13px;
}

.managers_affiliate p span b {
  font-size: 8px;
  margin-left: 5px;
}

.candidate_managerer_affiliate {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

.add_manager_btn {
  border: 1px solid #034A8C;
  background-color: transparent;
  color: #034A8C;
  padding: 7px 10px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;

}

.dropdown-manager {
  background-color: #fff;
  border: 1px solid #cccccc9c;
  border-radius: 10px;
  padding: 11px;
  position: absolute;
  left: 0;
  top: 37px;
  z-index: 9999999999;
  min-width: 200px;
}

.second-dropdown-manager {
  left: -37px !important;
}

.dropdown-manager input {
  border-radius: 5px;
  font-size: 13px;
  padding: 6px 4px;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.dropdown-manager ul {
  border: 1px solid #e0e0e0;
  margin-top: 10px;
  border-radius: 5px;
  /* padding: 6px; */
  padding: 0;
}

.dropdown-manager ul li {
  background-color: transparent;
  list-style: none;
  font-size: 14px;
  padding: 3px 10px;
  border-bottom: 1px solid #e0e0e0;
}

.addmanager {
  position: relative;
}

.addmanager input {
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #cccccc;
}

.addmanager ul {
  padding: 0px !important;
}

.addmanager ul li {
  list-style: none;
  padding: 5px 15px;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 6px;
  border-radius: 10px;
  text-align: left;
  font-weight: 500;

}

.addmanager ul li:first-child {
  margin-top: 10px;
}

.addmanager ul li:hover {
  background-color: #F7F8F9;
}

.saveManager {
  align-items: center;
  background-color: #ffc501;
  border: none;
  border-radius: 10px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  margin: 0 auto;
  padding: 6px 10px;
  width: 100%;
}

.addmanager ul {
  max-height: 125px;
  border: 1px solid #cccccc;
  margin-top: 10px;
  border-radius: 5px;
  margin-bottom: 7px;

}

.candidate_tags {
  display: flex;
  padding: 15px 0px;
  gap: 15px;
}

.candidate_tags span {
  background-color: #CACFD3;
  color: #4A4D50;
  font-size: 12px;
  padding: 6px 17px;
  border-radius: 7px;
}

.candidate_tags .plus_tag button {
  background-color: white;
  border: none;
  color: #045FB5;
  font-size: 13px;
  font-weight: 400;
}

.dropdown-tag {
  background-color: white;
  box-shadow: 0px 1px 5px #ccccccd1;
  padding: 15px;
  border-radius: 10px;
  min-width: 200px;
  margin-top: 2px;
  position: absolute;
  z-index: 9999;
}

.dropdown-tag input {
  padding: 7px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #7d8287;
}

.dropdown-tag ul {
  margin-left: 0px;
  padding-left: 0px;
  list-style: none;
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 1px;
  overflow-y: scroll;
  max-height: 150px;

}

.dropdown-tag ul li {
  font-size: 12px;
  list-style: none;
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 5px;
}

.dropdown-tag .saveTag {
  background-color: #ffc500;
  color: #000000;
  font-size: 12px;
  padding: 5px 20px;
  border-radius: 10px;
}

.create-new {
  color: #7d8288 !important;
  cursor: pointer;
  font-size: 11px !important;
}