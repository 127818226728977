.dropdown-container {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px #0000001a;
    /* padding: 20px; */
    width: 100%;
    padding-bottom: 10px;
}

.dropdown-container .header_profile {
    padding: 20px;
    border-bottom: 1px solid #eeeff0;
}



.dropdown-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 15px;
}

.dropdown-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px;
    font-size: 13px;
    max-height: 500px;
    overflow: scroll;
}



.dropdown-item {
    align-items: center;
    background: #f9f9f9;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    padding: 10px;

}


.dropdown-container .add-button {
    background-color: #FFC404;
    border: none;
    padding: 8px 16px;
    border-radius: 10px;
    font-size: 13px;
    color: #6B5202;
}

.dropdown-item svg {
    width: 20px;
    height: 20px;
}

.dropdown-item span {
    color: #4b4d50;
    font-size: 14px;
}

.dropdown-item:hover {
    background: #e6e6e6;
}

.edit-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #555;
}

.edit-button:hover {
    color: #000;
}

.dropdown-container .header_profile {
    padding: 20px;
    border-bottom: 1px solid #eeeff0;
}

.dropdown-container .header_profile h2 {
    font-size: 16px;
    padding: 0;
    margin: 0;
}