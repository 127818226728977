.box_count{
    background-color: white;
    display: flex
;
    /* align-items: center; */
    padding: 20px 20px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
}

.box_count_profile img{
    width: 45px;
    height: 45px;
    margin-right: 20px;
}

 .counting_number h6{
    font-size: 45px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: bold;
}

 .counting_number h6 span{
    color: #ffc500;
    padding: 0;
    border: none;
    font-size: 12px;
}

.counting_number p{
    font-size: 14px;
}

