.add_new_filter_company {
  background-color: transparent;
  border: none;
  padding: 10px 15px;
  border: 1px solid #616569;
  color: #616569;
  border-radius: 10px;
  font-size: 13px;
  margin-right: 10px;
}

.filterIcon {
  width: 12px;
  height: 12px;
  margin-right: 7px;
}

.edit_filter_company {
  background-color: #616569;
  border: none;
  padding: 10px 15px;
  border: 1px solid #616569;
  color: white;
  border-radius: 10px;
  font-size: 13px;
  margin-right: 20px;
}

.main-box-filter {
  position: absolute;
  top: 0;
  right: 0;
  width: 380px;
  background-color: white;
  min-height: 110vh;
  display: none;
  overflow-y: scroll;

}


/* Visible state */
.main-box-filter.show {
  display: block;
  padding: 0px;
}

.mainbox-filter-header {
  display: flex;
  justify-content: space-between;
  padding: 18px 15px;
  border-bottom: 1px solid #cacfd3;
}

.mainbox-filter-header h6 {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #4A4D50;
}

.mainbox-filter-header span {
  margin-left: 30px;
}

.clearFilter {
  display: flex;
  align-items: center;
}

.clearFilter h5 {
  font-size: 15px;
  margin-bottom: 0px;
}

.main-box-filter .modal-main {
  padding: 10px 20px;
}

.all_company_filters_component {
  padding: 10px 20px;
  height: 84vh;
  overflow-y: scroll;
}