.kanban-container {
    display: flex;
    justify-content: space-between;
}

.kanban-column {
    width: 30%;
    background: #f4f4f4;
    padding: 10px;
    border-radius: 8px;
}

.kanban-card {
    background: white;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.company-logo img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.company-name {
    font-weight: bold;
}

.kanban-container {
    display: flex;
    justify-content: space-between;
}

.kanban-column {
    width: 30%;
    background: #f4f4f4;
    padding: 10px;
    border-radius: 8px;
}

.kanban-card {
    background: white;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.company-logo img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.company-name {
    font-weight: bold;
}

.kanbanviews-fields span {
    font-size: 14px !important;
}

.kanbanviews-fields strong {
    margin-bottom: 12px !important;
    background-color: #EDEDED;
    text-align: left;
    padding: 7px 10px;
    color: black;
    width: fit-content;
    border-radius: 7px;
}

.kanbanviews-fields .grid-field {
    width: 271px;
    padding: 15px;
    border-right: none;
}

.kanbanviews-fields .grid-field .candidate_list_name {
    max-height: 160px;
    overflow-y: scroll;
    overflow-x: scroll;
    padding-right: 15px;
}

.kanban-company {
    overflow-x: scroll;
}

.kanbanviews-fields .update_status {
    display: flex;
    align-items: center;
}

.kanbanviews-fields .update_status p {
    margin-bottom: 0px;
    font-size: 12px;
    margin-left: 3px;
    color: gray;
}