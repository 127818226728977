.pipeline_sec {
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: white;
}

.pipeline_sec h4 {
    font-size: 14px;
    padding-bottom: 10px;
}

.pipeline_sec .pipeline_list {
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    background-color: #e0e0df;
    margin-bottom: 10px;
    cursor: pointer;
}

.pipeline_sec .pipeline_list:first-child {
    margin-top: 20px;
}

.pipeline_sec .submitted_candidate,
.pipeline_sec .total_applicants {
    background-color: #E6F3FF;
}

.pipeline_sec .disqualified_applicants {
    background-color: #FFB2B2;
    color: #6B0202
}

.pipeline_sec .sent_offer {
    background-color: #EDFFE6;
    color: green;
}

.pipeline_sec .hired {
    background-color: #C6FFB2;
    color: green;
}