.certification-modal {
  width: 50%;
  margin: auto;
  background-color: #fff;

  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.certification-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}

.certifications-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tag {
  background-color: #FFC404;
  padding: 5px 10px;
  color: #5f6063;
  border-radius: 20px;
  font-size: 12px !important;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.tag button {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.save-btn {
  background-color: #FFC404;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.certificate-heading {
  padding: 10px 0px;
}

.certificate-heading h4 {
  font-size: 14px;
  font-weight: 600;
}

.main_certification_heading {
  font-size: 16px;
}

.certificate-heading input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}

.certificate-heading label {
  font-size: 13px;
  font-weight: 600;
  padding-bottom: 8px;
}