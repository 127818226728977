
  .tags-input {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3em;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 0px;
    border-bottom: 0px;
    background-color:white;
    padding-left: 40px;
   
  }

.email_sent_editor{
    position: relative;
}
  
  .to_email{
    position: absolute;
    top: 14px;
    z-index: 99999;
    left: 10px;
    font-weight: bold;
    color: #4A4D50;
    font-size: 14px;
  }
  .email-tag {
    display: inline-block;
    background-color: #e0e0e0;
    padding: 0px 15px;
    border-radius: 20px;
    font-size:13px;
    height: fit-content;
  }
  
  .email-tag button {
    background: none;
    border: none;
    font-weight: bold;
    cursor: pointer;
    margin-left: 0.3em;
  }
  
  .tags-input input {
    border: none;
    outline: none;
    flex-grow: 1;
  }

  .tags-input input::placeholder{
    font-size: 13px;
  }
  
  .subject-text{
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 0;
    border-bottom: 0px;
    font-size: 13px;
  }


  .footer_text_area{
    border: 1px solid #cccccc;
    background-color: white;
    padding: 14px;
    border-top: 0;
    display: flex;
    justify-content: flex-end;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .footer_text_area button{
    padding: 7px 30px;
    background-color: #ffc500;
    border: 1px solid #ffc500;
    position: relative;

    border-radius: 10px;
   
  }


  