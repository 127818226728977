.file_list{
    background-color: white;
    display: flex
;
    justify-content: space-between;
    align-items: center;
    padding: 13px 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px #808080a8;
   
}

.more_icon_list{
    width: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.showMenu{
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    z-index: 1000;
    width: 200px;
}

.showMenu ul li img{
    width: 20px;
    margin-right: 10px;
}

.showMenu ul li{
    padding: 7px 10px !important;
    color: #545659;
    font-size: 14px;
}



.showMenu ul li:first-child{
    padding: 10px !important;
}

.modal-delete .modal-content{
    position: absolute;
    top: 50%;
    z-index: 99999999999;
    left: 50%;
    width: 300px;
    box-shadow: 0 4px 8px rgb(0 0 0 / 27%);
}
.modal-delete .modal-content p{
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}
.button_delete_cancle{
    display: flex;
    align-items: center;
    gap:10px;
}

.button_delete_cancle button{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #545659;
    width: 50%;
    font-size: 14px;
}


.button_delete_cancle button:first-child{
    background-color: #ffc500;
    border: 1px solid #ffc500;
}
.modal-delete .modal-content img{
    width: 60px;
    margin: 0 auto;
    padding-bottom: 10px;
}

.rename_input input{
    font-size: 14px;
    padding: 7px 12px !important;
    border: 1px solid #b0b7bf;
    min-width: 200px;
    margin-right: 10px;
    border-radius: 10px;
 
}


.rename_input{
    display: flex;
}
.rename_input .buttons{
   gap:7px;

display: flex;
}

.rename_input .buttons button{
    border-radius: 10px;
    font-size: 14px;
    padding: 5px 15px;
    border: 1px solid #545659;
    background: white;
}
.rename_input .buttons button:first-child{
background-color: #ffc500;

border: 1px solid #ffc500;
}

.goback_name{
    display: flex
    ;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 10px;
}
.goback_name button{
    border: none;
    padding: 0px;
    color: #045FB5;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
}

.goback_name button span{
    margin-right: 10px;
}

.goback_name p{
    margin-bottom: 0px;
    padding-bottom: 0px;
}