.company-listing {
    padding: 20px 0px;
  }
  
  .search-box {
    margin-bottom: 20px;
    padding: 8px;
    border: 1px solid #cccccc;
    width: 50%;
    box-sizing: border-box;
    border-radius: 10px;
    /* border: none; */
    padding: 13px;
    font-size: 14px;
  }
  
  .company-card {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
  }

  .company-card .trash_icon{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .stage-dropdown {
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .hired {
    background-color: #a2f3a1;
  }
  
  .sent-offer {
    background-color: #f3e6a1;
  }
  
  .interviewed {
    background-color: #a1caf3;
  }
  
  .applied {
    background-color: #f3a1a1;
  }
  
  .company-cards{
    max-height: 300px;
    overflow-y: scroll;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
  }

  .search_functionality{
    display: flex;
    justify-content: space-between;
  }

  .search_functionality select{
    padding: 13px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    font-size: 14px;
  }

  .search_functionality select:first-child{
    margin-right: 10px;
  }

  .filter-options span{
    font-weight: 600;
  }

  .company-card h3{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 1px;
    color: #034A8C;
  }

  .company-card p{
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    color: #034A8C !important;
  }

  .stage-dropdown{
    padding: 10px 30px;
  }


  .company_profile{
    display: flex;
    align-items: center;
    padding-bottom: 10px;
  }


  .company_profile .company_logo{
    margin-right: 10px;
  }

  .disqualified{
background-color: #FFB2B2;
color: #B50404;
font-weight: 500;
  }


  .select_discolified_button{
    display: flex;
  }

.select_discolified_button select{
  margin-right: 10px;
}
.select_discolified_button .discolified_icon{
  background-color: #f3a1a1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 10px;
}
.select_discolified_button .discolified_icon img{
  width: 25px;
  cursor: pointer;
}