.manager-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.manager-modal-main {
  border: 1px solid #cacfd3;
  border-radius: 10px;
  margin-top: 10px;
  padding: 0px !important;
}

.manager-search-bar {
  width: 100%;
  padding: 8px;

  border: 1px solid #ccc;
  border-radius: 4px;
}






.manager-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.manager-item.selected {
  background-color: #e8e9eb;
  border: 1px solid #fec501;
  border-radius: 10px;
}

.manager-item.selected p {
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.manager-modal-main h6 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: 600;
  color: #4b4d50;
  font-size: 14px;
}

.manager-modal-main svg {
  width: 20px;
  height: 20px;
  margin-right: 3px;
}

.icon-user {
  width: 20px;
  margin-right: 5px;
}

.manager-modal-main .manager-search-bar {
  padding: 12px;
  border-radius: 10px;
}

.manager-modal-main .done-button {
  background-color: #ffc500;
  color: #6c5202;
  padding: 13px 15px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 14px;
  border: none;
  width: 100%;
}

.manager-list .manager-item p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.manager-list {
  background-color: #f6f6f8;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;

}

.selected-manager-name {
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 14px;

}

.selected_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected_header .manage-icons img {
  width: 22px;
  cursor: pointer;
}

.selected_header .manage-icons img:first-child {
  margin-right: 5px;
}

.no-results {
  text-align: center;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.manager-list-radio-buttons label {
  font-size: 13px !important;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tag {
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.remove-tag {
  background: none;
  border: none;
  margin-left: 5px;
  cursor: pointer;
  font-size: 14px;
  color: red;
}