.course_sections{
    padding:30px 0px;
}

.course_module_section{
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px #80808052;
   
}

.module-title{
    font-size: 18px;
    font-weight: 600;

}

.module-header{
    
    padding:20px 20px 0px;
}

.module-content{
    padding:10px 20px;
}

.module-content iframe{
    width: 100%;
    border-radius: 10px;
    height: 350px;
}

.tab-navigation button{
    border: none;
    background-color: transparent;
 
}

.tab-navigation{
  display: flex;
  padding-top: 15px;
}

.transcriptbtn{
    width: 100%;
    text-align: left;
}

.tab-navigation button{
    padding-bottom: 15px;
    border-bottom: 2px solid gray;
    font-size: 16px;
    font-weight: 600;
    padding-right: 30px;
    padding-left: 30px;

}

.active-tab{
    border-bottom: 2px solid #ffc500 !important;
}

.tab-content{
    padding:20px 0px;
}
.module-header{
    display: flex;
    justify-content: space-between;
}

.module-header img{
    width: 30px;
}
.module_footer{
    padding:15px 20px;
    border-top: 1px solid #c7c8c9;
    display: flex;
    justify-content: flex-end;
}

.next_module{
    background-color: #ffc500;
    color: white;
    padding:10px 20px;
    border: none;
    border-radius: 10px;
    opacity: 1;
  
}

.chapter-list{
    font-weight: 600;
    padding: 5px 0px;
  
}



.module_ul li{
    padding:10px;
    cursor: pointer;
}

.module_ul li:hover{
    background-color: #E6F3FF;
}

.module_ul{
    list-style-type: none;
}

.module_ul li img{
    width: 20px;
    height: 20px;
}

.module_ul li{
    display: flex;
    justify-content: space-between;
}

.detail-progress{
   padding-top:0px;
   margin-top: 5px;
}
