.verified_icon{
    width: 20px;
    position: absolute;
    right: 5px;
}

.allAccessPlan{
    background-color: #9345f8  !important;
   color: white;
  }
  
  .basicPlan{
    background-color: #e8e9eb;
  }

  .enterprisePlan{
background-color: #B58B03;
color: white;
}

.company-list-table tr th{
min-width: 273px;
}