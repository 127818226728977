/* styles/Header.css */
header {
    background-color: black;
    color: #fff;
    padding: 9px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header h1 {
    margin: 0;
    font-size: 22px;
    padding: 10px;
}

header nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

header nav ul li {
    margin-left: 20px;
}

header nav ul li a {
    color: #fff;
    font-weight: bold;
}

.logo-img {
    width: 120px;
    padding-left: 5px;
}

.bell-icon {
    color: transparent;
    stroke: white;
    stroke-width: 2px;
    stroke-opacity: 1;
}


li a {
    text-decoration: none;
}

.profile-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.bellIcon-img {
    width: 25px;
}

.profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    margin-right: 10px;
    background-color: #fad256;
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-image h2 {
    margin-bottom: 0px !important;
    padding-bottom: 0px;
    font-size: 20px;
    text-transform: uppercase;
}

.arrow {
    font-size: 14px;
    color: white;
}

.dropdown-menu-profile {
    position: absolute;
    right: 15px;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 10px;
    z-index: 1;
    top: 58px;
    min-width: 150px;
}

.dropdown-menu-profile button {
    background-color: transparent;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 8px 12px;
    text-align: left;
    width: 100%;
}

.dropdown-menu-profile button:hover {
    background-color: #f1f1f1;
}

.exist-profile-image-header img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.setting-icon {
    width: 34px;
    margin-right: 12px;
}