.target-modal {
  width: 50%;
  margin: auto;
  background-color: #fff;

  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.target-modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.suggestions {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto;
}

.suggestion-item {
  padding: 10px;
  cursor: pointer;
}

.suggestion-item:hover {
  background-color: #f1f1f1;
}

.targets-tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.tag {
  background-color: #FFC404;
  padding: 5px 10px;
  color: #5f6063;
  border-radius: 20px;
  font-size: 15px;
  margin-right: 5px;
  margin-top: 5px;
  display: flex;
  width: fit-content !important;
  align-items: center;
}

.tag button {
  background: none;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.save-btn {
  background-color: #FFC404;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.target-heading {
  padding: 15px 20px 5px;
}

.target-heading h4 {
  font-size: 16px;
  font-weight: 700;
}

.target-heading input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}

.target-section .tag {
  background-color: transparent;
  padding: 7px 10px;
  border: 1px solid #5f6063;
  color: #5f6063;
  border-radius: 20px;
  font-size: 15px;
  margin-right: 5px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}


.tag.active {
  background-color: #ffc107;
  /* Active (yellow) color */
  color: white;
  border-color: #ffc107;
}


.industries-list {
  display: flex;
  flex-wrap: wrap;
}

.target-industries-popup input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 100%;
}

.target-industries-popup {
  padding-top: 20px;
  padding-bottom: 10px;
}

.target-industries-popup {
  padding: 20px 0px !important;
}