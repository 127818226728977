/* Container */
.admin-update-profile {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin: auto;
}

/* Header */
.admin-update-profile h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.admin-update-profile p {
    color: #666;
    margin-bottom: 20px;
}

/* Profile Photo */
.profile-photo {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
}

.profile-photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.upload-photo {
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
}

.upload-photo:hover {
    text-decoration: underline;
}

/* Form */
.admin-update-profile form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.admin-update-profile label {
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
    color: #4A4D50;
}

.admin-update-profile input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.admin-update-profile input[disabled] {
    background: #f3f3f3;
}

/* File Upload Box */
.file-upload {
    grid-column: span 2;
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.file-upload:hover {
    border-color: #007bff;
}

/* Buttons */
.action-buttons {

    position: absolute;
    top: 5px;
    right: 20px;
}

.action-buttons .cancel-btn {
    border: none;
    background-color: transparent;
    font-size: 13px;
    color: red;
    margin-right: 7px;
}

.action-buttons svg {
    width: 12px;
    height: 12px;
}

.action-buttons .save-btn {
    border: none;
    background-color: transparent !important;
    font-size: 13px !important;
    color: green !important;
}

.updated-profile-container {
    border: 1px solid #cacfd3;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    position: relative;
}

.updated-profile-container h2 {
    font-size: 17px;
    color: "#4b4d50";
}

.section_profile .file-upload-box {
    border: 1px dashed #B0B7BE;
    background-color: #F7F8F9;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 50%;
}

.section_profile .file-upload-box p {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}

.profile-photo-container {
    display: flex;
    align-items: center;
}

.updated-profile-container .form-group {
    margin-bottom: 15px;
    display: flex !important;
    gap: 20px;
}

.file-upload-text {
    font-weight: 800;
    font-size: 15px;
    color: #333333;
}

.file-support {
    font-size: 13px;
}

.upload-label {
    font-size: 12px;
}

.admin-update-profile .profile-photo-container img {
    margin-right: 10px;
}