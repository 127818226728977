.add_user_section {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 1px 2px #e0e2e5;
}

.add_user_section .add_user_header {
    border-bottom: 1px solid #b0b7bf;
}

.add_user_section .add_user_header h3 {
    font-size: 16px;
    font-weight: 600;
    padding: 20px;
    margin-bottom: 0px !important;
}

.add_user_body {
    padding: 20px;
    border-bottom: 1px solid #b0b7bf;
}

.add_user_body label {
    display: block;
    padding-bottom: 10px;
    color: #4A4D50;
    font-size: 12px;
    font-weight: 600;
}

.add_user_body input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
    margin-bottom: 10px;
}

.add_user_footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}

.add_user_footer button {
    background-color: #ffc500;
    border: none;
    padding: 12px 15px;
    border-radius: 10px;
    font-size: 12px;
}

.add_user_footer button:first-child {
    margin-right: 10px;
    background-color: transparent;
    color: #4A4D50;
    border: none;
    padding: 10px 15px;
}


.add_user_body select {
    width: 100%;
    padding: 12px 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
}