.candidate-details-popup {
  position: fixed;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  background-color: #F7F8F9;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  /* Start with opacity 0 for fade effect */
  transform: translateX(100%);
  /* Start off-screen */
  display: none;
  /* Hidden initially */

}

.editIcon {
  cursor: pointer;
}

.offer_letter_section .upload-files {
  margin: 0px;
}

.offer_letter_section {
  padding: 30px 0px 0px 0px;
}

.upload_resume_section .upload-box {
  margin: 0px;
}

.upload_resume_section {
  padding: 0px;
  position: relative;
}

.upload_resume_section .btn-save-changes {
  top: 18px;
}


.upload_resume_section h6 {
  font-size: 15px;
  font-weight: 600;
}

.offer_letter_section h6 {
  font-size: 15px;
  font-weight: 600;
}

.candidate-details-popup.show {
  display: block;
  /* Show popup */
  animation: slideIn 0.6s ease forwards;
  /* Slide in and fade in */
}

.candidate-details-popup.hide {
  animation: slideOut 0.6s ease forwards;
  /* Slide out and fade out */
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.popup-content {
  padding: 0px;
  padding-bottom: 15px;
}

.close-button {
  background-color: #F7F8F9;
  border: none;
  color: black;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: right;
  position: absolute;
  left: -44px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}



.profile_row .left_column {
  border-right: 1px solid #B0B7BE;

  height: 100vh;
  padding: 0px 20px;
  overflow-y: scroll;
  margin-bottom: 20px;
  padding-right: 10px;
}


.profile_row .left_column .popup_left_profile {

  margin-bottom: 20px;
}

.certification h4 {
  font-size: 16px;
}

.popup_right_profile {
  padding: 20px 30px 20px 20px;
  margin-bottom: 20px;
  overflow-y: scroll;
  max-height: 100vh;
}

.tab-buttons {
  padding: 10px;
  background-color: white;
  border-bottom: 0px;
  border: 1px solid #cccccc;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}


.tab-buttons button {
  border: none;
  background-color: transparent;
  font-weight: 600;
  color: #4A4D50;
  font-size: 14px;

}

.tab-buttons button:first-child {
  margin-right: 10px;
}

.tab-buttons .active {
  color: black;

  position: relative;
}

.tab-buttons .active::before {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 3px;
  background-color: #ffc500;
  top: 32px;
  z-index: 999;
}


.recent-section-buttons {
  background-color: #E7E9EB;
  margin-top: 20px;
  border-radius: 10px;
  padding: 14px;
  display: flex;
  justify-content: space-between;
}

.recent-section-buttons button {
  padding: 10px;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  font-weight: 600;
  font-size: 14px;
}

.recent-section-buttons .active {
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  border: none;
  font-weight: 600;
  font-size: 14px;
}

.more_icon {
  position: relative;
}

.dropdown-edit-profile {
  position: absolute;
  right: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  z-index: 1;
  top: 28px !important;
  min-width: 200px;
  border-radius: 8px;

}

.overlay {
  width: 100%;
  min-height: 100vh;
  background-color: #000000d6;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
}

.dropdown-edit-profile button {
  font-size: 13px;
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  padding: 8px 5px;
  text-align: left;
  border-radius: 5px;
  width: 100%;
}

.dropdown-edit-profile button:hover {
  background-color: #f1f1f1;
}

.file-type-prompt {
  padding: 15px;
}

.file-type-prompt button {

  padding: 10px;
  border-radius: 10px;
  border: none;
  margin-right: 10px;
  min-width: 150px;
  background-color: transparent;
  border: 1px solid #333;
  font-size: 14px;
}

.file-type-prompt button:first-child {
  background-color: #ffc500;
  border: 1px solid #ffc500;

}


.view_resume_iframe {
  padding: 20px;
}

.resume_header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

}

.resume_header button {
  background-color: initial;
  color: #045fb5;
  font-weight: 600;
  font-size: 13px;
  border: none;
}

.ResumeTitle {
  font-size: 13px;
}