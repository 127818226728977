.section_profile {
    border: 1px solid #CACFD3;

    border-radius: 10px;
    background-color: white;
}

.section_profile .header_profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-bottom: 1px solid #CACFD3;
}

.section_profile .header_profile h2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 18px;
}



.profile_card,
.email_card {
    background: transparent;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #cacfd3;
    margin: 20px;
}

.profile_card {
    position: relative;
}

.profile_card h3,
.email_card h3 {
    font-size: 16px;
    color: #4A4D50;
    font-weight:600;
}


.profile_card p,
.email_card p {
    font-size: 14px;
    color: #4A4D50;
}


.profile_info {
    display: flex;
    align-items: center;
}

.profile_image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-right: 15px;
}

.profile_details p {
    margin: 5px 0;
}



.connect_google {
    align-items: center;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    padding: 12px;
    width: 100%;
    font-size: 12px;
    color: #4A4D50;
}

.google_icon {
    width: 20px;
    margin-right: 10px;
}

.gsuite_connected {
    margin-top: 20px;
    font-weight: bold;
    color: #4A4D50;
    font-size: 13px;
}



.profile_photo_admin {
    display: flex;
    align-items: center;

}

.profile_photo_admin img {
    margin-right: 10px;
}

.section_profile .ProfileDefaultImg {
    margin-right: 10px !important;
}

.section_profile .ProfileDefaultImg h2 {
    font-size: 36px;
    font-weight: 600;
}

.profile_photo_admin strong {
    font-size: 14px;
    color: #4b4d50;
    text-transform: capitalize;
}

.section_profile .profile_details {
    padding-left: 10%;
}

.section_profile a {
    color: #4b4d50;
}

.section_profile .edit_btn {
    color: #4c4d50;
    font-size: 14px;
    padding-left: 5px;
    position: absolute;
    right: 15px;
    top: 10px;
    background-color: transparent;
    border: none;
}

.section_profile svg {
    width: 20px;
    height: 20px;
    margin-right: 3px;
}