.file-placeholder-uploadfiles{
    display: flex;
    align-items: center;
}

.file-placeholder-uploadfiles img{
    margin-right: 10px;
    width: 30px;
}

.file-placeholder-uploadfiles p{
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
}
