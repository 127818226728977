.ReactCrop {
    max-height: 80vh;
    max-width: 80vw;
  }
  .ReactModal__Overlay {
    background-color: #000000db;
    position: fixed;
    top: 0;
    opacity: 1;
    left: 0;
    z-index: 99999999;
    width: 100%;
    height: 100%;
}

.upload-box{
  border: 2px dashed #ccc;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
  background-color: #F7F8F9;
  border-radius: 10px;
  margin-left: 20px;
}

.upload-heading{
  padding: 10px 20px 5px;
}

.popup-left-heading{
  font-size: 18px;
  font-weight: bold;
}

.uploadImg{
  width: 80px;
}

.left-content-popup{
  padding-right: 20px;
}

.progress-bar{
  border:1px dashed gray;
  padding:10px;
  margin:20px !important;
  text-align: left;
  display: flex !important;
  width:100%;
}

.progress-bar img{
  width: 40px;
  margin-right:20px;
}

.error-message {
  color: red !important;
  font-weight: 600;
  padding-left: 20px;
  text-align: center;
  padding: 15px 0px 15px;
  border: 1px dashed;
  margin: 20px;
  border-radius: 10px;
}

