.ReactModal__Overlay {
    background-color: #000000cc !important;
    z-index: 2000;
}

.close-btn {
    position: absolute;
    right: 10px;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: 18px;
    top: 10px;
}

.popup-content h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 5px;

}

.popup-content p {
    color: #4A4D50;
}

.popup-header {
    padding: 20px 20px 0px;
}

.popup-form {
    padding: 0px 20px 20px;
}

.updateprofileform .two-fields {
    display: block;
    margin-bottom: 0px;
}

.form-field {
    display: block;
    width: 100%;


}

.updateprofileform .form-field {
    padding-top: 2px;
}

.form-field input,
.form-field select {
    width: 100%;
    padding: 14px;
    border-radius: 10px;
    border: 1px solid #ddddde;
}

.form-field label span {
    color: red;
}

.form-field label {
    font-weight: bold;
    padding-bottom: 5px;
}

.popup-content .form-btn {
    padding: 10px 20px 25px;
    float: right;
}

.popup-content .form-btn button {
    border: none;
    padding: 10px 20px;
    border: 1px solid #ffc500;
    background: #ffc500;
    color: #5f6063;
    border-radius: 10px;
}

.ReactModal__Content {
    max-height: 800px;
    overflow-y: scroll;
}

textarea {
    width: 100%;
    border: 1px solid #ddddde;
}

textarea:focus {
    border: 1px solid #ddddde;
}

.checkbox-main {
    display: flex;
    gap: 20px;
}

.checkboxs {
    display: flex;
    align-items: center;
    border: 1px solid #dcdcdd;
    padding: 12px;
    border-radius: 10px;
    width: 50%;
}

.error-border {
    border: 1px solid red !important;
}

.error-message-field {
    color: red;
    font-size: 13px;
    font-weight: 600;
}

.updateprofileform {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
    overflow-y: scroll;
    position: relative;
}

.updateprofileform h6 {
    font-size: 14px;
    font-weight: 700;
}

.updateprofileform .popup-form {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}

.updateprofileform .popup-content .form-btn {
    padding: 0px !important;
}



.updateprofileform input {
    padding: 10px;
    font-size: 14px !important;
}

.location_address p {
    font-size: 12px !important;
}

.updateprofileform h5 {
    font-size: 13px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    padding-top: 10px;
    font-weight: 600;
}

.form-field .error-message {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    font-size: 10px !important;
}