
  
  .certification-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width:100%;
    max-height: 80%;
    margin-top: 15px;
    overflow-y: auto;
    border: 1px solid #cacfd3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .certification-search-bar {
    width: 100%;
    padding: 8px;

    border: 1px solid #ccc;
    border-radius: 4px;
  }
  


  
  .certification-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
  }
  
  .certification-item.selected {
    background-color: #e8e9eb;
    border: 1px solid  #fec501;
    border-radius: 10px;
  }
  .certification-item.selected  p{
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .certification-modal-main h6{
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 600;
    color: #4b4d50;
    font-size: 16px;
  }

  .icon-user{
    width: 20px;
    margin-right: 5px;
  }
  
  .certification-modal-main .certification-search-bar{
    padding: 12px;
    border-radius: 10px;
  }
.certification-modal-main .done-button{
  background-color: #ffc500;
  color: #6c5202;
  padding: 13px 15px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 14px;
  border: none;
  width: 100%;
}

.certification-list .certification-item p{
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.certification-list{
  background-color: #f6f6f8;
  font-size: 14px;
  cursor: pointer;
  margin-top: 15px;
  
}

.selected-certification-name{
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: 14px;

}
.selected_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selected_header .manage-icons img{
  width: 22px;
  cursor: pointer;
}
.selected_header .manage-icons img:first-child{
  margin-right: 5px;
}
.no-results{
  text-align: center;
margin-top: 10px;
padding-top: 5px;
  padding-bottom:5px;
  margin-bottom: 0;
}

.icon-certification{
  width: 20px;
  margin-right: 5px;
}
.certification-list-radio-buttons{
 padding:10px 0px;
}

.certification-list-radio-buttons label{
  padding-bottom: 10px;
  font-size: 14px;
}

.certification-list-radio-buttons label input[type="radio"]{
  margin-right: 5px;
}

.selected-certifications{
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  font-size: 14px;
}