.container {
  width: 100%;
  background: white;
  border-radius: 8px;
  padding-bottom: 10px;
  padding: 0px;
}

.container .header_profile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 15px;
}

.list_input {
  padding: 10px 20px;
}

.search-input {
  padding: 12px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #e7e8e9;
  margin-bottom: 10px;
}

.edit_dropdown_item {
  list-style: none;
  padding: 0;
  margin: 0px;
  font-weight: 600;
}

.dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 6px;
  margin-bottom: 5px;
}

.icon-group {
  display: flex;
  gap: 10px;
}

.edit-icon,
.delete-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #555;
}

.edit-icon:hover {
  color: #007bff;
}

.delete-icon:hover {
  color: #dc3545;
}

/* Edit Mode */
.edit-mode {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
}

.edit-mode svg {
  width: 14px;
  height: 13px;
}

.edit-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.check-icon,
.cancel-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.check-icon {
  color: green;
}

.cancel-icon {
  color: red;
}

.edit_profile_ul {
  margin: 0px;
  padding: 0px;
}

.delete-popup {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 3px #8080807d;
  padding: 15px;
  position: absolute;
  right: 0%;
  width: 300px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.delete-popup h2 {
  font-size: 16px;
}

.delete-popup p {
  font-size: 13px;
}

.delete-popup .popup-buttons {
  display: flex;
  gap: 10px;
}

.delete-popup svg {
  margin-bottom: 5px;
}

.delete-popup .cancel-button {
  background-color: transparent;
  border: 1px solid #4b4d50;
  width: 50%;
  font-size: 12px;
}



.delete-popup .delete-button {
  background-color: #FF8C8C;
  border: 1px solid #FF8C8C;
  width: 50%;
  font-size: 12px;
  padding: 8px;
  border-radius: 5px;
  color: #6B0202;
}