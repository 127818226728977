.reviews_section {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    margin-top: 10px;
}

.reviews_section h4 {
    font-size: 14px;
    margin-bottom: 15px;
}

.reviews_list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.review_card {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border: 1px solid #cacfd3;
    border-radius: 10px;
    background-color: white;
}

.review_content p {
    font-size: 13px;
    margin: 7px 0 10px 0;
    color: #212529;
}

.review_content h5 {
    font-size: 14px;
    margin: 0;
    color: #6C6C6C;
    font-weight: 600;
}

.review_content span {
    font-size: 12px;
    color: #6C6C6C;
}

.more_icon {
    display: flex;
    /* align-items: center; */
    cursor: pointer;
}

.show_more button {
    border: none;
    background-color: transparent;
    color: #034a8c;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    margin-top: 10px;
}