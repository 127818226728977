.course-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjusts space between items */
}

.course-item {
    flex: 0 1 calc(33.33% - 20px); /* Each item takes up 1/3 of the width with some margin */
    box-sizing: border-box; /* Ensures padding and border are included in width */
    margin: 10px; /* Space between items */
    border: 1px solid #ccc; /* Optional: adds a border around each course item */
    padding: 10px; /* Optional: adds padding inside each course item */
}

.course-card .card img{
    width: 100%;
}

.course-card{
    padding-top: 20px;
}

h4{
    font-weight: 600;
}

.card-title{
    padding:14px 0px;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding-bottom: 10px;
}

.course-card p{
    padding:0px;
    margin: 0px;
}

.custom-progress {
    background-color: #e0e0e0; /* Background color for the progress bar */
    border-radius: 5px; /* Rounded corners */
    height: 20px; /* Height of the progress bar */
    width: 100%; /* Full width */
    position: relative; /* For positioning inner text */
    margin:15px 0px 15px;
  }
  
  .custom-progress-bar {
    background-color: #84FF58; /* Green color for the progress */
    height: 100%; /* Full height */
    border-radius: 5px; /* Rounded corners */
    text-align: center; /* Center text */
    color: #1E6B02; /* Text color */
    transition: width 0.3s ease; /* Smooth transition */
    display: flex;
    justify-content: center;
    padding-left: 20px;
  }
  

  .course-card .card{
    padding: 20px 20px 0px;
    border-radius: 10px;
    box-shadow: 0px 1px 6px #dededeb3;

  }

  .course-desc-half {
    white-space: nowrap; /* Prevents text from wrapping */
    overflow: hidden; /* Hides the overflowing text */
    text-overflow: ellipsis; /* Adds "..." at the end */
}

.card .card-body{
    padding-left: 0px;
    padding-right: 0px;
}

.card a{
    color: black;
}

.card:hover{
    box-shadow:0px 1px 11px #8080804f;
}

.chapter-heading{
    list-style-type: none;
    padding-left: 0;
}

.left-chapter-list{
    padding-left: 30px;
}

.down_img{
    width: 15px;
}

.chapter-list-arrow{
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

.module_ul li.active {
    background-color: #E6F3FF; 
    padding:15px;/* Example: change background color */
    border-radius: 10px;
}
  