.relocation-filter-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-height: 80%;
    margin-top: 15px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #cacfd3;
}

.relocation-search-bar {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #7D8287;
}

.relocation-filter-modal-main h6 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 600;
    color: #4b4d50;
    font-size: 14px;
}

.relocation-filter-modal-main svg {
    width: 20px;
}

.icon-user {
    width: 20px;
    margin-right: 5px;
}

.relocation-item {
    background-color: #f6f6f8;
    font-size: 14px;
    margin-top: 5px;
    cursor: pointer;
    padding: 5px;
}

.no-results {
    text-align: center;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
    font-size: 14px;
}

.done-button {
    background-color: #ffc500;
    color: #6c5202;
    padding: 13px 15px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 14px;
    border: none;
    width: 100%;
}

.selected-relocation-name {
    padding-bottom: 0px;
    padding-top: 10px;
    font-size: 14px;
    margin-bottom: 0px;
}

.relocation-list-radio-buttons {
    padding: 10px 0px;
}

.relocation-list-radio-buttons label {
    display: block;
    padding-bottom: 3px;
    font-size: 14px;
}

.relocation-list-radio-buttons label input[type="radio"] {
    margin-right: 10px;
}