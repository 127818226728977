.text-search-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-search-bar {
  width: 100%;
  padding: 8px;

  border: 1px solid #ccc;
  border-radius: 4px;
}

.filter-type-dropdown {
  padding: 10px 0px;
  display: block;
}

.filter-type-dropdown label {
  padding: 3px 0px;
  font-size: 14px;
  display: block;
}

.filter-type-dropdown input[type="radio"] {
  margin-right: 5px;
}

.text-search-modal-main {
  border: 1px solid #cacfd3;
  border-radius: 10px;
  margin-top: 10px;

}

.text-search-modal-main svg {
  width: 18px;
  height: 18px;
  margin-right: 3px;
}

.candidate-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.candidate-item.selected {
  background-color: #e8e9eb;
  border: 1px solid #fec501;
  border-radius: 10px;
}

.candidate-item.selected p {
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.text-search-modal-main h6 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: 600;
  color: #4b4d50;
  font-size: 14px;
}

.icon-user {
  width: 20px;
  margin-right: 5px;
}

.text-search-modal-main .manager-search-bar {
  padding: 12px;
  border-radius: 10px;
}

.text-search-modal-main .done-button {
  background-color: #ffc500;
  color: #6c5202;
  padding: 13px 15px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 14px;
  border: none;
  width: 100%;
}

.candidate-list .candidate-item p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.candidate-list {
  background-color: #f6f6f8;
  font-size: 14px;
  margin-top: 5px;

}

.no-results {
  text-align: center;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
}

.main-box-filter .modal-main {
  padding: 0px !important;
}