.empty-state .plusIcon img {
  width: 25px !important;
  right: 15px;
}

.empty-state img {
  width: 100px !important;
}

.empty-state p {
  font-size: 14px !important;
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height for centering */
  background-color: black;
}

.empty-state button {
  padding: 10px 15px !important;
}

.empty-state .plusIcon img {
  top: 10px !important;
}

.spinner {
  border: 8px solid #f3f3f3;
  /* Light grey */
  border-top: 8px solid #f1ba02;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  /* Spinner size */
  height: 50px;
  /* Spinner size */
  animation: spin 1s linear infinite;
  /* Spin animation */
}

.suggestion-item {
  font-size: 14px !important;
  padding: 5px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.logo-pre-loader {
  width: 200px !important;
  margin-bottom: 14px;
}

.loading-container h6 {
  color: white;
  padding-top: 15px;
  display: none;
}

.progress-bar-container .progress-bar {
  border: none;
  margin-left: 0px !important;
  padding-left: 0px;
  background-color: #E7E9EB;
  padding: 0;
}

.main-heading {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.popup-content h2 {
  font-size: 18px;
}

.step-buttons {
  float: right;
  margin-top: 20px;
  margin-bottom: 60px;
}

.previous-btn {
  margin-right: 10px;
}


.next-btn,
.complete-btn {
  background-color: #f1ba02;
  color: #6B5202;
  padding: 10px 30px;
  border-radius: 10px;
  border: 1px solid #f1ba02;
}

.next-btn:hover,
.complete-btn:hover {
  background-color: transparent;
  color: #4f5154;
  border: 1px solid #4f5154;
}

.prev-btn {
  background-color: transparent;
  color: #4f5154;
  border: 1px solid #4f5154;
  padding: 10px 30px;
  margin-right: 10px;

}

.company-profile-page .popup-content {
  background-color: white;
  height: auto;
  padding-bottom: 90px;

  padding-bottom: 90px;
  border-radius: 10px;

}

video {
  margin-bottom: 20px;
}

.profile-banner-candidate {
  position: relative;
}

.branch-logo {
  position: absolute;
  top: 18px;
  right: 20%;
}

.branch-logo img {
  width: 200px;
}

.candidate-profile-func .ProfileDefaultImg {
  width: 130px;
  height: 130px;
  background-color: #FFD757;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: 0;
  align-items: center;
  padding: 0;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;

}

.ProfileDefaultImg h2 {
  font-size: 40px;
  color: white;
  padding-bottom: 0px;
  margin-bottom: 0;
  font-size: 59px;
  font-weight: bold;
  text-transform: uppercase;
}

.profile-box-candidate {
  background-color: white;
  margin-top: 60px;
  padding: 20px;
  border-radius: 10px;
}

.profile-box-candidate .Profile-Img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.candidateType {
  background-color: #8CC7FF;
  color: #02386B;
  padding: 5px 15px;
  border-radius: 10px;
  margin-top: 20px !important;
  font-weight: 300;
  text-align: center;
}

.candidate_type {
  text-align: center;
}

.candidate-profile-img {
  margin: 0 auto;
  text-align: center;
  margin-top: 15px;

}

.profile-box-candidate h4 {
  font-size: 24px;
  text-align: center;
  padding-top: 12px;
  font-weight: 700;
  padding-bottom: 0px;
  margin-bottom: 3px;
}

.candidate-branch {
  text-align: center;
}

.candidate-info {
  text-align: center;
}

.candidate-info span {
  font-weight: 600;
  font-size: 18px;
}

.share-btn {
  margin: 0 auto;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}

.share-btn img {
  width: 100px;
}

.candidate-profile-page main {
  margin-right: 0px;
  padding-right: 0px;
}

.profile-view-resume {
  background-color: #f1ba02;
  color: #6B5202;
  padding: 10px 20px;
  width: 100%;
  font-size: 16px;

  border: 1px solid #f1ba02;
  border-radius: 10px;
  margin-top: 10px;
}

.Edit-profile-btn-profile {
  background-color: transparent;
  color: #4f5154;
  padding: 10px 20px;
  border: 1px solid #4f5154 !important;
  width: 100%;

  border: none;
  border-radius: 10px;
  margin-top: 7px;
}

.Edit-profile-btn-profile a {
  color: #4f5154;
  font-size: 16px;
}

.dashboard {
  background-color: #f6f6f8;
}

main {
  padding: 20px 15px;
  padding-right: 30px;
}

.welcome-text {
  color: gray;
  font-weight: Regular;
}

.welcome-text span {
  font-weight: bold;
  color: black;
}

.sidebarCollapsed {
  width: 7% !important;
}

.contentExpanded {
  width: 93% !important;
}

.contentbar {
  padding-left: 0px !important;
}

.main_heading_dashboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.main_heading_dashboard h4 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-right: 10px;
}

.main_heading_dashboard span {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  border: 1px solid #CACFD3;

}

.main_heading_dashboard .left_content {
  display: flex;
}

.main_heading_dashboard .right_content button {
  color: #6B5202;
  background-color: #ffc500;
  padding: 10px 20px;
  border: 1px solid #ffc500;
  border-radius: 10px;
  font-size: 15px;
}

.main_heading_dashboard .right_content button:hover {
  background-color: transparent;
  color: #4f5154;
  border: 1px solid #4f5154;
}

.candidate_table_records tbody,
td,
tfoot,
th,
thead,
tr {

  text-align: left;

}

tbody th {
  border-right: 1px solid gray;
}

.candidate_table_records table {
  width: 100%;
  border-radius: 10px;
  border: none;
}

.candidate_table_records {
  margin: 10px 0px 0px 0px;
}

.candidate_table_records table th {
  padding: 12px 16px;
  background-color: #E7E9EB;

}


.candidate_table_records td {
  padding: 8px 16px;
  border: none;
  border-right: 1px solid #cacfd3;
}

.candidate_table_records {
  width: 100%;
}

.table_candidates {
  width: 100%;
  overflow-x: scroll;
  border-radius: 10px;
}

.candidate_table_records tr th {
  border-right: 1px solid #CACFD3;
  border-top: 1px solid #CACFD3;
  border-bottom: 1px solid #CACFD3;
  min-width: 242px;
  font-size: 14px;
}

.candidate_table_records tr th:last-child {
  border: none;
}

tbody tr {
  background-color: white;
}

tbody tr td {
  font-size: 14px;
}

tbody tr td:first-child {
  color: #034A8C !important;
  font-weight: 500;
  display: flex !important;
  align-items: center;
  position: relative;

}

.candidate_table_records td:nth-child(2) span {
  padding: 5px 20px;
  border-radius: 10px;
  font-size: 13px;
}

tbody tr {
  border-bottom: 1px solid #cacfd3 !important;
}

.managerName {
  color: #024a8d;
  font-weight: 500;
}

.add_new_filter {
  border: none;
  padding: 14px 30px;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 15px;
  background-color: #ffc500;
  border: 1px solid #ffc500;
  color: #6c5202;
}

.add_new_filter:hover {
  background-color: transparent;
  border: 1px solid #4f5154;
  color: #4f5154;
}

.pagination-limit {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

#recordsPerPage {
  padding: 4px;
  margin-left: 10px;
  border-radius: 10px;
}

.pagination-limit label {
  font-size: 14px;
}

.pagination_numbers button {
  border: 1px solid #4A4D50;
  background-color: white;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  color: #4A4D50;
}


.pagination_numbers .active {
  background-color: #4A4D50;
  color: white;
}

.select_options_filter {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
}

.select_options_filter a {
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.select_options_filter select {
  padding: 12px 30px;
  border-radius: 10px;
  margin-left: 10px;
  font-size: 15px;
}

.toggle-btns img {
  width: 20px;

}

.toggle-btns button:first-child {
  /* background-color: white; */
  border-right: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.search_bar {
  position: relative !important;
}

.toggle-btns {
  position: absolute;
  top: 0;
  right: -65px;

}

.toggle-btns button {
  border: 1px solid #cacfd3;
  padding: 10px;
  background-color: white;


}

.toggle-btns button.active {
  background-color: #cacfd3;
  color: white;
}

.toggle-btns button:nth-child(2) {
  border-right: 0px;
}


.toggle-btns button:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.recently-added {
  padding: 12px 10px !important;
  margin-right: 14px;
}


.select_options_filter {
  align-items: center;
}

.select_options_filter select {
  font-size: 14px;
}

.veteran-class span {
  background-color: #01284C;
  color: white;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px !important;

}

.transitioning-class span {
  background-color: #8CC7FF;
  color: #01284C;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px !important;
}

.addFilterModal-candidate-page .modal-main {
  position: absolute;
  right: 0px;
  width: 370px;
  min-height: 100vh;
  background-color: white;
  top: 0px;
  padding: 10px 15px;
}

.candidate_main_box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  margin-left: 0px !important;
  margin-right: 0px;
}

.candidate_main_box .main_heading_dashboard {
  color: black;
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}


.candidate_main_box .main_heading_dashboard h4 {
  font-size: 20px;
}

.candidate_main_box .main_heading_dashboard .left_content {
  align-items: center;
}

.company_main_box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;

  margin-top: 20px;
  margin-left: 0px !important;
}

.company_main_box .main_heading_dashboard h4 {
  font-size: 20px;

}

.company_main_box .main_heading_dashboard {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.dashboardrow {
  max-height: 91vh;
  min-height: 91vh !important;
}

.dashboardrow main {
  overflow-y: scroll;
  max-height: 90vh;
}

input:focus-visible {
  border: 1px solid black;
  outline: none;
}

.edit_column_icon {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-right: 15px;
}

.edit_column_icon button {
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #4f5154;
}

.edit_column_icon img {
  width: 25px;
}


@media screen and (max-width:567px) {
  .dashboardrow main {
    margin-left: 40px !important;
  }
}

/* Responsive Search Filters */
@media screen and (max-width:768px) {
  .dashboardrow main {
    margin-left: 20px;
  }




  .search_filter input {
    padding: 12px 15px !important;
    width: 75% !important;
  }

  .search_filter {
    margin-top: 15px !important;
  }

  .add_new_filter_company {
    font-size: 12px !important;
  }

  .edit_filter_company {
    font-size: 12px !important;
  }

  .candidate_table_records tr th {
    font-size: 13px;
  }
}

@media screen and (max-width:660px) {
  .search_filter input {
    width: 71% !important;
  }
}

@media screen and (max-width:460px) {
  .dashboardrow main {
    margin-left: 43px !important;
  }

  .main_heading_dashboard h4 {
    color: black;
    font-size: 18px !important;
  }

  .main_heading_dashboard span {
    font-size: 8px !important;
  }

  .collapsed .sidebar-icons {
    width: 19px;
  }

  .main_heading_dashboard .right_content button {
    font-size: 10px !important;
  }

  .select_options_filter {
    display: block;
  }

  .select_options_filter select {
    float: right;
    margin-top: 10px;
  }

  .search_filter input {
    padding: 5px 15px !important;
    width: 56% !important;
  }

}

.dashboard_toggles {
  right: 10%;
}