.candidate-type-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.manager-search-bar {
  width: 100%;
  padding: 8px;

  border: 1px solid #ccc;
  border-radius: 4px;
}

.candidate-type-list-radio-buttons {
  padding: 10px 0px;
}



.candidate-type-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.candidate-type-item.selected {
  background-color: #e8e9eb;
  border: 1px solid #fec501;
  border-radius: 10px;
}

.candidate-type-item.selected p {
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.candidate-type-modal-main h6 {
  margin-bottom: 0px;
  padding-bottom: 0px;
  font-weight: 600;
  color: #4b4d50;
  font-size: 14px;
}

.icon-user {
  width: 20px;
  margin-right: 5px;
}

.candidate-type-modal-main .candidate-type-search-bar {
  padding: 12px;
  border-radius: 10px;
  padding: 12px;
  border-radius: 10px;
  width: 100%;
  border: 1px solid #7D8287;
}

.candidate-type-modal-main .done-button {
  background-color: #ffc500;
  color: #6c5202;
  padding: 13px 15px;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 14px;
  border: none;
  width: 100%;
}

.candidate-type-modal-main label {
  display: block;
  padding: 3px 0px;
  font-size: 13px;
}

.candidate-type-modal-main input[type="radio"] {
  margin-right: 10px;
}

.candidate-type-LIST .candidate-type-item p {
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.candidate-type-item {
  background-color: #f6f6f8;
  font-size: 14px;
  margin-top: 5px;

}

.no-results {
  text-align: center;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 0;
  font-size: 14px;
}

.selected-candidate-type-name {
  margin-bottom: 0;
  padding-top: 10px;
  font-size: 14px;
}

.candidate-type-modal-main {
  border: 1px solid #cacfd3;
  margin-top: 10px;
  border-radius: 10px;
}

.candidate-type-modal-main h6 svg {
  width: 20px;
  height: 20px;
}