.setting-page h2 {
    font-size: 22px;
    font-weight: 600;

}

.setting-section {
    padding-top: 20px;
}

body {
    background-color: #F7F8F9;
}