.grid-container {
  display: block;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 10px;
  background-color: white;
}



.grid-item {
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 15px;
  overflow-x: scroll;
  border-radius: 10px;
}

.grid-header-company {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
  background-color: #e8e9eb;
  padding: 10px;
}

.grid-header-company img {
  width: 25px !important;
  height: 25px !important;
}

.company-logo {
  margin-right: 10px;
  ;
}

.company-name {
  font-weight: bold;
  color: #024a8d;
  font-size: 14px;
}

.company-details {
  display: grid;
  gap: 10px;
}

.grid-fields {
  display: flex;
  gap: 0px;
}

.grid-field {
  display: block;
  text-align: center;
  justify-content: space-between;
  border-top: 1px solid #cacfd3;
  border-right: 1px solid #cacfd3;
  gap: 10px;
  width: 15%;
  padding: 15px;
}

.grid-field:nth-child(5) span,
.grid-field:nth-child(5) strong {
  color: green;
}

.grid-field:nth-child(6) span,
.grid-field:nth-child(6) strong {
  color: green;
}

.grid-field strong {
  display: block;
  font-size: 10px;
  font-weight: 400;
  color: #4b4d50;
  text-transform: uppercase;
}


.grid-field span {
  font-size: 30px;
  font-weight: 600;
  color: #024a8d;
}

.grid-content ul {
  display: flex;
  gap: 32px;
  font-size: 12px;
  margin-bottom: 0;
  /* margin-left: 30px; */
}

.grid-content li {
  font-size: 12px;
}

.verified_icon {
  width: 20px;
}



.enterprisePlanGrid {
  background-color: #B58B03;
  color: white;
  list-style: none;
  padding: 3px 15px;
  border-radius: 10px;
}

.basicPlanGrid {
  background-color: #616569;
  color: white;
  list-style: none;
  padding: 3px 15px;
  border-radius: 10px;
}

.allAccessPlanGrid {
  background-color: #9747FF;
  color: white;
  list-style: none;
  padding: 3px 15px;
  border-radius: 10px;
}