.search_filter input{
    padding: 12px 20px;
    border-radius: 10px;
    border: none;
    outline: 1px solid #DBDEE1;
    width: 50%;
}

.search_filter input:focus{
    border: none;
   outline:1px solid ;
}

.search_filter button{

    border: none;
    background-color: #ffc500;
    padding: 10px 20px !important;
    border-radius: 10px;
    color: #6c5202;
    font-size: 14px;

}

.search_filter input::placeholder{
    font-size: 14px;
}