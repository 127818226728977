.profileWarning-box{
    border: 1px solid #dc6903;
    display: flex;
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
    background-color: #FFFAEB;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.profileWarning-box .profileIcon img{
    width: 50px;
    margin-right: 10px;
}


.profileWarning-box .profile-description{
   display: flex;
}

.profile-description h4{
    color: #de6f12;
    font-weight: 0600;
    font-size: 22px;
}

.profile-description p{
    color: #6B5202;
    font-size: 16px;
}
.profile-description ul li{
    color: #6B5202;
    font-weight: 600;
}

.complete-profile-btn{
    background-color: #f1ba02;
    border: none;
    padding: 12px 40px;
    border-radius: 10px;
    color: #6c5202;
    font-weight: 500;
  
}

.hide-box{
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 18px;
}