.recentactivity_compontent{
    padding: 20px 0px;
}
.activity_main_bar{
    display: flex;
    justify-content: space-between;
}

.activities_box{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}
.activities_box .box_main_bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.activity_heading{
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.profile_info_img
{
    color: #034A8C;
    display: flex;
    align-items: center;
    
}

.profile_info_img h6{
    font-size: 14px;
    padding-left: 10px;
    padding-bottom: 0;
    margin-bottom: 0px;
}

 .date_time{
    font-size: 14px;
}
.activities_desc p{
    padding-top: 10px;
}

.profile_img_activity{
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.activities_box{
    margin-bottom: 10px;
}