.profile-box-wrap-dashboard {
    background-color: white;
    margin-top: 15px;
    display: flex;
    align-items: center;
    border-radius: 10px;

}

.profile-box-wrap-dashboard h2 {
    font-weight: bold;
    font-size: 25px;
}

.company-name-dashboard h2 {
    display: flex;
    text-transform: capitalize;
}

.company-name-dashboard h2 img {
    width: 45px;
    margin-left: 8px;
    height: 25px;

}

.profile-box-wrap-dashboard h5 {
    font-size: 15px;
    color: #5e6063;
    font-weight: 600;
}

.profile-box-wrap-dashboard h6 {
    font-size: 14px;
    color: #5f6063;
    font-weight: 500;
}

.profile-box-wrap-dashboard .left-box {
    display: flex;
    align-items: center;
}

.right-box .editprofile {
    display: block;

}

.right-box .editprofile button {
    width: 124px;
    height: 46px;
    margin-bottom: 10px;
    border: 1px solid #5f6063;
    color: #5f6063;
    background-color: white;
    padding: 8px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
}

.right-box .viewprofile button {
    width: 124px;
    height: 46px;
    margin-bottom: 10px;
    border: 1px solid #ffc500;
    color: #5f6063;
    background-color: #ffc500;
    padding: 8px;
    font-weight: 400;
    border-radius: 10px;
    font-size: 14px;
}

.candidateType {
    background-color: #8CC7FF;
    color: #02386B;
    padding: 5px 20px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 20px !important;
    font-weight: 300;
}

.candidateIdentity {
    margin: 13px 0px 13px;
}

.profile-box-img {
    position: relative;

}


.profile-box-img .profileEdit {
    bottom: 5px;

    right: 18px;
    position: absolute;

    cursor: pointer;
    border: 1px solid gray;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.profile-box-img .profileEdit svg {
    width: 16px;

}

.ProfileDefaultImg {
    align-items: center;
    background-color: #ffd757;
    border-radius: 50%;
    display: flex;
    height: 100px;
    justify-content: center;
    margin: 0 15px 0 0;
    padding: 0;
    width: 100px;

}

.ProfileDefaultImg h2 {
    font-size: 40px;
    color: white;
    padding-bottom: 0px;
    margin-bottom: 0;
    font-size: 59px;
    font-weight: bold;
    text-transform: uppercase;
}

.company-logo-img {
    width: 120px !important;
    height: 120px;
    padding: 0 !important;
    margin-right: 15px;
    border-radius: 50% !important;
    object-fit: cover;
}