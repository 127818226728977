.section_email_templates {
    border: 1px solid #CACFD3;

    border-radius: 10px;
    background-color: white;
    margin: 20px;
}

.section_email_templates .header_email_templates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #CACFD3;
}

.email-template-header {
    padding: 15px;
}

.email-template-header h6 {
    margin-bottom: 0px;
    font-size: 15px;
    color: #045FB5;
    font-weight: 600;
}

.section_email_templates .header_email_templates h2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 18px;
}

.section_email_templates .header_email_templates button {
    background-color: #FFC404;
    color: #6B5202;
    border: none;
    padding: 12px 13px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
}

.section_email_templates .email_templates_table {
    padding: 0px 20px 5px;

}

.section_email_templates .email_templates_table table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #CACFD3;
}

.section_email_templates .email_templates_table table th {
    border-right: 1px solid #CACFD3;
    background-color: #E7E9EB;
}

.email_templates_table th {
    width: 25%;
    font-size: 14px;
    padding: 12px 10px;
}


.email_templates_table td {
    color: #4A4D50;
    font-size: 13px;
    padding: 12px 10px;
    border-bottom: none;
}