.add-military .form-field {
    display: block;
    width: 100%;
    padding-bottom: 0px;
}

.add-military input {
    padding: 14px;
}

.popup-form {

    padding: 20px 0px 5px 0px !important;
}

.form-field label {
    font-size: 14px
}