.ReactModal__Overlay {
    background-color: #000000cc !important
}

.close-btn {
    position: absolute;
    right: 10px;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: 18px;
    top: 10px;
}

.popup-content h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 5px;

}

.popup-content p {
    color: #4A4D50;
    font-size: 14px;
}

.popup-header {
    padding: 20px 20px 0px;
}

.popup-form {
    padding: 20px 20px;
}

.two-fields {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.tag {
    background-color: #E7E9EB !important;
    padding: 5px 10px;
    color: #5f6063;
    border-radius: 20px;
    font-size: 14px !important;
    margin-right: 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;

}

.form-field {
    display: block;
    width: 100%;


}

.form-field input,
.form-field select {
    width: 100%;
    padding: 14px;
    border-radius: 10px;
    border: 1px solid #ddddde;
}

.form-field label span {
    color: red;
}

.form-field label {
    font-weight: 600;
    padding-bottom: 5px;
}

.popup-content .form-btn {
    padding: 10px 20px 25px;
    float: right;
}

.popup-content .form-btn button {
    border: none;
    padding: 10px 20px;
    border: 1px solid #ffc500;
    background: #ffc500;
    color: #5f6063;
    border-radius: 10px;
}

.about-form {
    overflow-y: scroll;
    height: 500px;
    padding: 0px !important;
}

.about-form textarea {

    min-height: 100px;
    border-radius: 10px;
    padding: 12px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.about-form .sub-span {
    font-size: 12px;
}

.about-form .form-field {
    margin-bottom: 0px;

}

.about-form .form-field label {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;

}

select {
    font-size: 14px;
}

textarea {
    font-size: 14px;
}

input {
    font-size: 14px;
}

input::placeholder {
    font-size: 14px;
}

textarea::placeholder {
    font-size: 14px;
}

.about-form .two-fields {
    margin-bottom: 0;
    padding-bottom: 0px;
    display: block;
}

.about-form .save-btn {
    float: right;
}

.error-border {
    border: 2px solid red !important;
}

input,
textarea,
select {
    color: #7D8287;
}

.update-job-search-form select {
    font-size: 13px;
}