.watch_my_intro {
    background-color: white;
    padding: 20px 20px;
    border-radius: 10px;
    box-shadow: 0px 1px 7px #7a7a7a2b;
    padding-bottom: 5px;
    margin-top: 20px;

    position: relative;
}

.watch_my_intro h4 {
    font-size: 18px;
    font-weight: 600;

}

.empty-state {
    margin: 0 auto;
    text-align: center;

    padding-bottom: 30px;
}

.empty-state img {
    width: 160px;


}

.empty-state p {
    color: #7D8287;
    font-size: 17px;
    padding-bottom: 10px;
}

.empty-state button {

    margin-bottom: 10px;
    border: 1px solid #ffc500;
    color: #5f6063;
    background-color: #ffc500;
    padding: 13px 30px;
    font-weight: 400;
    border-radius: 10px;
    font-size: 14px;
}

.empty-state .plusIcon img {
    width: 30px;
    position: absolute;
    right: 2%;
    top: 6%;
    cursor: pointer;
}

.row-about-fields {
    display: block;
    width: 100%;
    padding-bottom: 10px;

}

.row-about-fields .left-column-box {
    padding-bottom: 10px;
}

.existing-info-about {
    padding: 0px;
}

.existing-info-about .description {
    padding-bottom: 0px;
}

.row-about-fields p {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.row-about-fields {
    padding-bottom: 14px;
}

.watch_my_intro h4 {
    font-size: 15px !important;
}

.watch_my_intro b {

    font-size: 14px;
    font-weight: 600;

}

.watch_my_intro p {
    font-size: 14px;
}