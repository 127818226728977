.error-page-content .icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    /* Background mein rakha hai, icon visible rahega */
}

.error-page {
    position: relative;
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
}

.error-page .sub-error {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
    z-index: 1;
    /* Foreground mein rahega, clickable hoga */
}

.error-page h2 {
    text-align: center;
    font-weight: 700;
    color: black;
    font-size: 44px;
    z-index: 2;
    /* Foreground mein rahega, clickable hoga */
}

.error-page p {
    text-align: center;
    font-size: 16px;
    color: #474747;
    z-index: 1;
    /* Foreground mein rahega, clickable hoga */
}

.back-to-home {
    display: flex;
    align-items: center;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    z-index: 1;
    /* Foreground mein rahega, clickable hoga */
}

.back-to-home a {
    background-color: #FFC404;
    color: #6B5202;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 14px;
    z-index: 1;
    /* Foreground mein rahega, clickable hoga */
}

.error-page-footer {
    margin-top: 20px;
    z-index: 1;
    /* Foreground mein rahega, clickable hoga */
}

.error-page-footer p {
    margin-bottom: 0px;
    font-size: 12px;
}

.error-page-footer p a {
    cursor: pointer;
}