.jobsearch-status-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #cacfd3;
  margin-top: 10px;
  border-radius: 10px;

}

.status-list-radio-buttons {
  padding-top: 10px;
}



.selected_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-status-name {
  font-size: 14px;
  font-weight: 500;
  margin-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}


.status-list-radio-buttons label {
  cursor: pointer;
  display: block;
  font-size: 13px;
  margin-bottom: 0px;
  padding-top: 4px;

}

.status-list-radio-buttons input {
  margin-right: 10px;
}

.status-search-bar {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.done-button {
  margin-top: 0px !important;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}



.manage-icons svg {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}




.selected_header h6 {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.selected-status-name {
  font-size: 14px;
  font-weight: 500;
  color: #444;
}