.section_templates {
    border: 1px solid #CACFD3;

    border-radius: 10px;
    background-color: white;
}

.section_templates .header_templates {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #CACFD3;
}

.section_templates .header_templates h2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 18px;
}

.section_templates .header_templates button {
    background-color: #FFC404;
    color: #6B5202;
    border: none;
    padding: 12px 13px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
}