.section_users {
    border: 1px solid #CACFD3;

    border-radius: 10px;
    background-color: white;
}

.section_users .header_users {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-bottom: 1px solid #CACFD3;
}

.section_users .header_users h2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 18px;
}

.section_users .header_users button {
    background-color: #FFC404;
    color: #6B5202;
    border: none;
    padding: 12px 13px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
}

.section_users .users_table {
    padding: 20px;

}

.section_users .users_table table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #CACFD3;
}

.section_users .users_table table th {
    border-right: 1px solid #CACFD3;
    background-color: #E7E9EB;
}

.users_table th {
    width: 25%;
    font-size: 14px;
    padding: 12px 10px;
}


.users_table td {
    color: #4A4D50;
    font-size: 13px;
    padding: 12px 10px;
    border-bottom: none;
}

/* Top Left & Top Right Borders */
.section_users .users_table table th:first-child {
    border-top-left-radius: 10px;
}

.section_users .users_table table th:last-child {
    border-top-right-radius: 10px;
}

/* Bottom Left & Bottom Right Borders */
.section_users .users_table table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.section_users .users_table table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}