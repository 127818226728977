.job_section {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    margin-top: 10px;
}

.job_section .job_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.job_header button {
    border: 1px solid #034A8C;
    background-color: transparent;
    color: #034A8C;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
}

.job_section .job_header h4 {
    font-size: 14px;

}

.job_lists {
    padding-top: 15px;
}

.job_list {
    padding: 10px;
    border: 1px solid #CACFD3;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 10px;
}

.job_list h5 {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #034A8C;

}

.job_list h5 span {
    font-size: 12px;
}

.job_list .job_detail {
    display: flex;
    gap: 20px;
    font-size: 12px;
}

.show_more button {
    border: none;
    background-color: transparent;
    color: #034A8C;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    font-size: 14px;
}