.submit_company_section{
    background-color: white;
 
    border-radius: 10px;
    box-shadow: 0px 1px 2px #e0e2e5;
}

.submit_company_section .submit_header{
   
border-bottom: 1px solid #b0b7bf;
}

.submit_company_section .submit_header h3{
    font-size: 20px;
    font-weight: 600;
    padding: 20px;
    margin-bottom: 0px !important;
}

.submit_body{
    padding: 20px;
    border-bottom: 1px solid #b0b7bf;
}

.submit_body label{
    display: block;
    padding-bottom: 10px;
    color: #4A4D50;
    font-size: 14px;
    font-weight: 600;
}

.submit_body input{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
    margin-bottom: 10px;
}

.submit_body textarea{
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #b0b7bf;
    min-height: 130px;
  
}

.submit_footer{
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}
.submit_footer button{
    background-color:#ffc500 ;
    border: none;
    padding: 12px 15px;
    border-radius: 10px;
    font-size: 14px;
}
.submit_footer button:first-child{
    margin-right: 10px;
    background-color: transparent;
    color: #4A4D50;
    border: none;
    padding: 10px 15px;
}

