.billing-info-section {
  margin-bottom: 10px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  position: relative;
}

.billing-info-section h3 {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}


.company_detail_group {
  display: flex;
  gap: 60px;
}


.edit-form input {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
}


.company_detail_group label {
  font-size: 14px;
  font-weight: 400;

}

.company_detail_group p {
  font-size: 14px;
  font-weight: 600;
  padding-top: 6px;
}

button {
  cursor: pointer;
}

.form-row-group {
  display: flex;
  gap: 20px
}

.form-row-group input {
  width: 220px;
}

.form-row-group label {
  font-size: 14px;
  padding-bottom: 5px;
}

.billing_address_field {
  width: 320px !important;
}

.buttons {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  font-size: 14px;

}

.edit_icon {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  font-size: 14px;

}

.buttons .save_btn {
  margin-left: 5px;
  background-color: #ffc500;

}

.buttons .save_btn {
  padding: 5px 15px;
  border: none;
  border-radius: 10px;
}

.buttons .close_btn {
  padding: 5px 15px;
  border: none;
  background-color: transparent;
  color: #616569;
}