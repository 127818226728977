.invoice-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: scroll;
}

.header_invoice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header_invoice h2 {
  font-size: 16px !important;
}




.create-invoice-button {
  background-color: transparent;
  color: #4f5154;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #4f5154;
  font-size: 13px;
}

.create-invoice-button:hover {
  background-color: #4f5154;
  color: white;
}

.invoice-table th {
  width: fit-content !important;
  min-width: fit-content !important;

}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.invoice-table th,
.invoice-table td {
  padding: 12px 15px;
  border: 1px solid #ddd;
  max-width: fit-content !important;
}

.invoice-table th {
  background-color: #f3f4f6;
}

.status {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9em;
  color: white;
  text-align: center;
}

.status.paid {
  background-color: #ABFF8C;
  color: green;
}

.status.unpaid {
  background-color: #FFC404;
}

.download-icon {
  cursor: pointer;
  color: #4f46e5;
}

.download-icon:hover {
  color: #4338ca;
}

.invoice-table td {
  border-right: 0px;
  border: none;
}