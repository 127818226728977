/* Form Container */
.form-container {

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.form-container .form_header {
  border-bottom: 1px solid #cccccc;
  padding: 20px;
}

/* Title */
.form-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0px;
}

.form-container .form-fields-group {
  padding: 20px;
}

/* Form Group */
.form-group {
  margin-bottom: 15px;
}

/* Label */
.form-label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
  color: #4A4D50;
}

/* Input Fields */
.form-input {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Textarea */
.form-input[rows] {
  resize: none;
}

/* Form Actions */
.form-actions-footer {
  text-align: right;
  border-top: 1px solid #ccc;
  padding: 15px;
}

/* Button */
.form-button {
  background-color: #f4c430;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.form-button:hover {
  background-color: #e0b020;
}


.close_create_invoice {
  background-color: transparent;
  border: none;
  color: #4A4D50;
  font-size: 14px;
  font-weight: 500;
  margin-right: 21px;
}