.resume_list{
    background-color: white;
    display: flex
;
    justify-content: space-between;
    align-items: center;
    padding: 13px 15px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0px 1px 3px 0px #808080a8;
}

.resume_list p{
    margin-bottom: 0px;
}