.left_setting_sidebar {
    background-color: white;
    padding: 15px 10px;
    /* border: 1px solid gray; */
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 1px 2px #80808054;
}


.left_setting_sidebar ul li {
    padding: 7px 10px;
    list-style-type: none;
    font-size: 13px;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    color: #4A4D50;
    font-weight: 400;
}


.left_setting_sidebar ul {
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
}

.left_setting_sidebar ul li:hover {
    background-color: #E5F3FF;

}

.left_setting_sidebar ul li.active {
    background-color: #E5F3FF;

}