.modal-content {
  background: #fff;
  padding: 20px;
  width: 400px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.upload-box {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.main-progress-bar {
  width: 95%;

}

.image-preview img {
  max-width: 100px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.progress-bar {
  background: #f3f3f3;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress {
  background: #4caf50;
  height: 10px;
  transition: width 0.3s;
}

.error-message {
  color: red;
}


.profile_upload_popup_content h2 {
  font-size: 18px !important;
  padding-bottom: 6px;
}

.profile_upload_popup_content .popup-header {
  display: block;
}

.profile_upload_popup_content .upload-heading {
  padding: 0px 20px;
}

.profile_upload_popup_content .drag-row {
  padding: 0px 20px;
}

.profile_upload_popup_content .left-content-popup {
  padding-right: 0px;
  margin-right: 0px;
}

.profile_upload_popup_content button {
  font-size: 12px !important;
}

.close-btn {
  font-size: 14px !important;
}