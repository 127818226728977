.job-status {
  display: flex;
  padding: 9px 0px 7px;
  align-items: center;
}

.job_search_number {
  font-size: 20px !important;
  margin-right: 10px;
  color: #1d6c02;
  margin-bottom: 0px;
}

.job-status p {
  color: #4f5154;
  font-weight: 500;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.search-slider-job {
  padding: 10px 30px;
}

/* Custom styling for range slider */
input[type="range"] {
  -webkit-appearance: none;
  /* Remove default appearance */
  width: 100%;
  height: 1px;
  border-radius: 10px;
  background: #ffc500;
  outline: none;
  opacity: 0.9;
  transition: opacity 0.15s ease-in-out;
}

input[type="range"]:hover {
  opacity: 1;
  /* Slight effect when hovering */
}

/* For Chrome/Edge/Safari */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  /* Thumb color */
  border: 2px solid #ffc500;
  /* Thumb border color */
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background-color: #ffc500;
  /* Change thumb color on hover */
}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #ffc500;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;

}

input[type="range"]::-moz-range-thumb:hover {
  background-color: #ffc500;
}

/* Styling for track in Firefox */
input[type="range"]::-moz-range-track {
  height: 1px;
  border-radius: 5px;
  background: #ffc500;
}

.slider-num {
  font-size: 20px;
  color: gray;
}

.pointer-label {
  font-size: 15px;
  margin-top: 30px;
  box-shadow: 0px 1px 4px #b2a4a4;
  width: fit-content;
  text-align: center;
  margin: 30px auto 0px;
  padding: 10px;
  border-radius: 10px;
}

.slider-num span {
  font-size: 16px;
}

/* Active number style with increased font size */
.slider-num .active-number {
  font-size: 33px;
  font-weight: bold;
  color: #ffc500;
}

.basicInformation {
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 14px;
  position: relative;
}

.basicInformation h4 {
  font-size: 15px;
}


.basicInformation .editIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.basicInformation .editIcon img {
  width: 25px;
}

.job-status p {
  font-size: 14px;
}

.btn-save-changes {
  background-color: #ffc500;
  position: absolute;
  top: 12px;
  right: 20px;
  border: none;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 12px;
}