.section_roles {
    border: 1px solid #CACFD3;

    border-radius: 10px;
    background-color: white;
}

.section_roles .header_roles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #CACFD3;
}

.section_roles .header_roles h2 {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 18px;
}

.section_roles .header_roles button {
    background-color: #FFC404;
    color: #6B5202;
    border: none;
    padding: 12px 13px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
}

.section_roles .roles_table {
    padding: 20px;

}

.section_roles .roles_table table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #CACFD3;
}

.section_roles .roles_table table th {
    border-right: 1px solid #CACFD3;
    background-color: #E7E9EB;
}

.roles_table th {
    width: 25%;
    font-size: 14px;
    padding: 12px 10px;
}


.roles_table td {
    color: #4A4D50;
    font-size: 13px;
    padding: 12px 10px;
    border-bottom: none;
}